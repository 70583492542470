import {
  AnyLayer,
  FeatureIdentifier,
  Map as MapboxMap,
  VectorSource,
} from 'mapbox-gl';

export default function createViz(
  map: MapboxMap,
  matchData: Map<Array<number>, [string, string]>,
  activeCountry: string
) {
  if (!map.isStyleLoaded()) {
    return;
  }

  if (map.getLayer('pos2-join')) {
    map.removeLayer('pos2-join');
  }

  if (map.getLayer('pos4-join')) {
    map.removeLayer('pos4-join');
  }

  if (map.getSource('postalFeatureDataboundaries_postal_2')) {
    map.removeSource('postalFeatureDataboundaries_postal_2');
  }

  if (map.getSource('postalFeatureDataboundaries_postal_4')) {
    map.removeSource('postalFeatureDataboundaries_postal_4');
  }

  //matchData is a map of "postal", "feature_id"
  // Get the vector tile source from lookup table
  // https://docs.mapbox.com/data/boundaries/reference/feature-lookup-tables/
  const sourceLayer =
    activeCountry === 'GB' || activeCountry === 'IE'
      ? 'boundaries_postal_2'
      : 'boundaries_postal_4'; //Specify data layer reference
  const sourcePropsUrl =
    activeCountry === 'GB' || activeCountry === 'IE'
      ? 'mapbox.boundaries-pos2-v3'
      : 'mapbox.boundaries-pos4-v3'; //Specify Mapbox underlying content

  //Typescript is stupid? Or is Mathias.
  //If I don't do this, the keys (clearly indicated as number) come up as String after Array.from( matchData.keys() )
  const featureIds: Array<number> = [];
  for (const keys of matchData.keys()) {
    for (const key of keys) {
      featureIds.push(key);
    }
  }

  const featureFilter = ['in', ['id'], ['literal', featureIds]];

  // Add source for matching enterprise boundaries
  const sourceProps: VectorSource = {
    type: 'vector',
    url: 'mapbox://' + sourcePropsUrl, //PolyTilesetName
  };

  let layerProps: AnyLayer;

  // Create layer for merged-states
  // https://docs.mapbox.com/mapbox-gl-js/example/updating-choropleth/

  if (activeCountry === 'GB') {
    layerProps = {
      id: 'pos2-join',
      type: 'fill',
      source: 'postalFeatureData' + sourceLayer,
      'source-layer': sourceLayer, //PolyLayerName
      paint: {
        'fill-color': ['feature-state', 'color'],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.75,
          0.5,
        ],
      },
      filter: featureFilter,
    };
  } else {
    layerProps = {
      id: 'pos4-join',
      type: 'fill',
      source: 'postalFeatureData' + sourceLayer,
      'source-layer': sourceLayer, //PolyLayerName
      paint: {
        'fill-color': ['feature-state', 'color'],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.75,
          0.5,
        ],
      },
      filter: featureFilter,
    };
  }

  map.addSource('postalFeatureData' + sourceLayer, sourceProps);
  map.addLayer(layerProps, 'waterway-label');

  //Associate colorAndId[1]s with ids and tell the map tile features about it
  console.log(`Rendering: ${matchData.size} features to the map`);

  matchData.forEach((colorAndId: [string, string], features: Array<number>) => {
    if (!colorAndId[1]) {
      console.log(`Bogus colorAndId[1] ${colorAndId[1]}`);
    }
    features.forEach((feature) => {
      if (
        feature === null ||
        feature === undefined ||
        feature === 0 ||
        feature < 0
      ) {
        console.log(`AWKWARD FEATURE ${feature}`);
      }
      if (
        colorAndId[1] === null ||
        colorAndId[1] === undefined ||
        colorAndId[1]?.length === 0 ||
        colorAndId[1]?.slice(0, 1) !== '#'
      ) {
        console.log(`AWKWARD colorAndId[1] ${colorAndId[1]}`);
      }
      if (feature) {
        const newFeature: FeatureIdentifier = {
          source: 'postalFeatureData' + sourceLayer,
          sourceLayer: sourceLayer,
          id: Number(feature),
        };
        map.setFeatureState(newFeature, {
          territory: colorAndId[0],
          color: colorAndId[1] || '#ffffff',
        });
      } else {
        console.log(`Bogus Feature ${feature}`);
      }
    });
  });

  return { sourceProps, layerProps };
}
