import { ExtendedMapboxFeaturesForTerritory, Facility, OptionEntry, Territory } from '@voiant/dataconnector';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  PostalCodeCodeAssignmentQueueMsg,
  ScenarioAssignmentQueMsg,
  TerritoryAssignmentQueueMsg
} from 'apps/dataprocessing/src/app/dataprocessing_models';
import { reducerMethods } from '../app.reducer';
import appConfig from '../../assets/config.json';

// //Above not working, so stuff is duplicated here for now (till we can tame typescript checks).
export enum ListType {
  none = 'none',
  regions = 'regions',
  modalities = 'modalities',
  submodalities = 'submodalities',
  zones = 'zones',
  sources = 'sources',
  markets = 'markets',
}

export class Api {
  public static readonly endpoint: string = appConfig.api;
  public static user: any;

  private DEBUG = false; //Can we set this at build time?
  readonly user;

  //TODO: Generic auth bits, keys, what have you.
  private api_key = ''; //How does this get here from paramstore if there's no real authentication?

  constructor(user: any) {
    this.user = user;
  }

  private standardHeaders() {
    return {
      // 'x-api-key': this.api_key,
      'X-Userid': this.user.attributes.email,
    };
  }

  private execApiMethod(method: any): any {
    try {
      if (this.DEBUG) {
        console.log(`API.execApiMethod requested with = ${method}`);
      }

      return method();
    } catch (e) {
      console.log('API Invocation failure!');
      console.error(e);
      //TODO: Consistent error handling method here
      // onError(e);
    }
  }

  private async getFacilityResults(
    territory: string,
    pageSize: number,
    facilitiesLoaded: number,
    source: string
  ): Promise<Facility[]> {
    const fetchedResult = await fetch(
      `${Api.endpoint}/facility?territory=${encodeURIComponent(
        territory
      )}&limit=${pageSize}&offset=${facilitiesLoaded}&source=${source}`,
      {
        method: 'GET',
        headers: this.standardHeaders(),
      }
    );

    return await fetchedResult.json();
  }

  private async getFacilityResultsParentSearch(
    parentName: string,
    subModality: string,
    pageSize: number,
    facilitiesLoaded: number
  ): Promise<Facility[]> {
    const fetchedResult = await fetch(
      `${Api.endpoint}/facility?submodality=${encodeURIComponent(
        subModality
      )}&parentName=${encodeURIComponent(
        parentName
      )}&limit=${pageSize}&offset=${facilitiesLoaded}`,
      {
        method: 'GET',
        headers: this.standardHeaders(),
      }
    );

    return await fetchedResult.json();
  }

  async getList(
    type: string,
    source: string,
    region: string,
    modality: string,
    submodality: string,
    zone: string,
    market: string
  ): Promise<Array<OptionEntry>> {
    return this.execApiMethod(async () => {
      // source, params.region, params.modality, params.submodality, params.zone
      const res = await fetch(
        `${Api.endpoint}/list?type=${type}&source=${encodeURIComponent(
          source
        )}&region=${encodeURIComponent(region)}&modality=${encodeURIComponent(
          modality
        )}&submodality=${encodeURIComponent(
          submodality
        )}&zone=${encodeURIComponent(zone)}&market=${encodeURIComponent(
          market
        )}`,
        {
          method: 'GET',
          headers: this.standardHeaders(),
        }
      );
      // const res = await fetch(`http://localhost:3000/list?type=regions`);
      const json = await res.json();
      return json;
    });
  }

  //TODO: I don't think this userId attribute is used...
  async getTerritoriesForUser(
    userId: string,
    source: string,
    region?: string,
    modality?: string,
    submodality?: string
  ): Promise<Array<Territory>> {
    return this.execApiMethod(async () => {
      const territories = await fetch(
        `${
          Api.endpoint
        }/territories?source=${source}&region=${encodeURIComponent(
          region || ''
        )}&modality=${encodeURIComponent(
          modality || ''
        )}&submodality=${encodeURIComponent(submodality || '')}`,
        {
          method: 'GET',
          headers: this.standardHeaders(),
        }

        // `http://localhost:3000/users/${userId}/territories`
      );
      const territoriesData = await territories.json(); //Why the heck does this require an await?
      return territoriesData.map(
        (territory: Territory) => territory as Territory
      );
      console.log('territoriesData', territoriesData);
    });
  }

  async getExtendedFeatureIdsForTerritory(
    country: string,
    territoryCodes: Array<string>
  ): Promise<Array<ExtendedMapboxFeaturesForTerritory>> {
    return this.execApiMethod(async () => {
      const extendedFeatures = await fetch(
        `${Api.endpoint}/territories/extendedFeatures`,
        {
          method: 'POST',
          body: JSON.stringify({
            country: country,
            territoryCodes: territoryCodes,
          }),
          headers: this.standardHeaders(),
        }
      );

      const extendedFeaturesData = await extendedFeatures.json();

      return extendedFeaturesData.map(
        // TODO: @Cvernino - is this necessary?
        (features: ExtendedMapboxFeaturesForTerritory) =>
          features as ExtendedMapboxFeaturesForTerritory
      );
    });
  }

  async getTerritoryCenters(
    territories: string[]
  ): Promise<{ [territoryId: string]: number[] }> {
    const url = new URL('territories/centers', Api.endpoint);
    url.searchParams.append('ids', territories.join(','));
    const fetchedResult = await fetch(
      //`${Api.endpoint}/territories/centers?ids=${territories.join(',')}`,
      url.href,
      {
        method: 'GET',
        headers: this.standardHeaders(),
      }
    );

    return await fetchedResult.json();
  }

  /**
   * @Charper I have an order of operations issue here.
   * The lag for the user load, which I guess I thought I needed to have fire first
   * is preventing the userId from being present when the lists are loaded.
   * So I have this littly hacky bit...but I don't like it.
   * TODO: Lets talk about startup sequencing in the react app
   */

  async getUser() {
    return this.execApiMethod(async () => {
      const res = await fetch(
        `${Api.endpoint}/users/${this.user.attributes.email}/`,
        {
          method: 'GET',
          headers: this.standardHeaders(),
        }
      );
      return await res.json();
    });
  }

  async getFacilitiesForTerritory(
    territory: string,
    source: string,
    setFacilitiesLoaded?: any,
    setTotalFacilities?: any,
    dispatch?: any
  ) {
    return await this.execApiMethod(async () => {
      const response = await (
        await fetch(
          `${Api.endpoint}/facility/count?territory=${encodeURIComponent(
            territory
          )}&source=${encodeURIComponent(source)}`,
          {
            method: 'GET',
            headers: this.standardHeaders(),
          }
        )
      ).json();

      const totalFacilities = Number(response.count);
      const pageSize = totalFacilities < 1000 ? totalFacilities : 1000;

      if (setTotalFacilities) {
        setTotalFacilities(totalFacilities);
      }
      let facilitiesArray: Facility[] = [];
      let facilitiesToLoad = 0;
      let facilitiesLoaded = 0;
      const fetchedPromises = [];

      while (facilitiesToLoad < totalFacilities) {
        fetchedPromises.push(
          new Promise<any>((resolve, reject) => {
            this.getFacilityResults(territory, pageSize, facilitiesToLoad, source).then(
              (results) => {
                facilitiesLoaded += results.length;
                if (setFacilitiesLoaded) {
                  setFacilitiesLoaded(facilitiesLoaded);
                }

                facilitiesArray = facilitiesArray.concat(results);

                if (dispatch) {
                  dispatch({
                    type: reducerMethods.setFacilities,
                    payload: facilitiesArray,
                  });
                }

                resolve(undefined);
              }
            );
          })
        );

        facilitiesToLoad += pageSize;
      }

      await Promise.all(fetchedPromises)
        .then(() => {
          console.log(
            `Success. All ${totalFacilities} facilities have been loaded.`
          );
        })
        .catch((err) => {
          console.log('Error when loading facilities.');
          console.error(err);
        });

      return facilitiesArray;
    });
  }

  async getFacilitiesParentSearch(
    parentName: string,
    subModality: string,
    setFacilitiesLoaded: any,
    setTotalFacilities: any,
    dispatch: any
  ) {
    return await this.execApiMethod(async () => {
      const response = await (
        await fetch(
          `${Api.endpoint}/facility/count?submodality=${encodeURIComponent(
            subModality
          )}&parentName=${encodeURIComponent(parentName)}`,
          {
            method: 'GET',
            headers: this.standardHeaders(),
          }
        )
      ).json();

      const totalFacilities = Number(response.count);
      const pageSize = totalFacilities < 1000 ? totalFacilities : 1000;
      setTotalFacilities(totalFacilities);

      let facilitiesArray: Facility[] = [];
      let facilitiesToLoad = 0;
      let facilitiesLoaded = 0;
      const fetchedPromises = [];

      while (facilitiesToLoad < totalFacilities) {
        fetchedPromises.push(
          new Promise<any>((resolve, reject) => {
            this.getFacilityResultsParentSearch(
              parentName,
              subModality,
              pageSize,
              facilitiesToLoad
            ).then((results) => {
              facilitiesLoaded += results.length;
              setFacilitiesLoaded(facilitiesLoaded);

              facilitiesArray = facilitiesArray.concat(results);

              dispatch({
                type: reducerMethods.setFacilities,
                payload: facilitiesArray,
              });

              resolve(undefined);
            });
          })
        );

        facilitiesToLoad += pageSize;
      }

      await Promise.all(fetchedPromises)
        .then(() => {
          console.log(
            `Success. All ${totalFacilities} facilities have been loaded.`
          );
        })
        .catch((err) => {
          console.log('Error when loading facilities.');
          console.error(err);
        });

      return facilitiesArray;
    });
  }

  async putTerritoriesForFacilities(
    facilities: TerritoryAssignmentQueueMsg
  ): Promise<Array<any>> {
    console.log('api facilities', facilities);
    return this.execApiMethod(async () => {
      const res = await fetch(
        `${Api.endpoint}/facility/assign_bulk_territories`,
        {
          method: 'PUT',
          body: JSON.stringify(facilities),
          headers: this.standardHeaders(),
        }
      );
      return await res.json();
    });
  }

  async putScenariosForFacilities(
    facilities: ScenarioAssignmentQueMsg
  ): Promise<Array<any>> {
    console.log('api scenarios for facilities', facilities);
    return this.execApiMethod(async () => {
      const res = await fetch(
        `${Api.endpoint}/facility/assign_bulk_scenarios`,
        {
          method: 'PUT',
          body: JSON.stringify(facilities),
          headers: this.standardHeaders(),
        }
      );
      return await res.json();
    });
  }

  async putTerritoriesForPostalCodes(
    postalCodeCodes: PostalCodeCodeAssignmentQueueMsg
  ): Promise<Array<any>> {
    console.log('api reassign postal codes to new territory ', postalCodeCodes);
    return this.execApiMethod(async () => {
      const res = await fetch(
        `${Api.endpoint}/territories/assign_postal_code_codes`,
        {
          method: 'PUT',
          body: JSON.stringify(postalCodeCodes),
          headers: this.standardHeaders(),
        }
      );
      return await res.json();
    });
  }
}
