import Loader from "react-loader-spinner";
import React from "react";
import { LoaderDiv } from './styles'

export default function Loading() {
  return (
    <LoaderDiv>
      <Loader type="TailSpin" color="#00BFFF" height={30} width={30} />
    </LoaderDiv>
  )
}
