import React, { useContext } from 'react';
import { FacilityPopup, ScenarioChangeText } from "../styles";
import { isFacilityAlteredInCurrentScenario } from "../markers";
import { Popup } from "react-map-gl";
import { FacilityData, FullContext } from "../../shared/types";
import { AppContext } from "../../../app.store";

export const AccountPopup = ({
  selectedFacility,
  setSelectedFacility
}: {
  selectedFacility: FacilityData;
  setSelectedFacility: (selectedFacility: FacilityData | undefined) => void;
}) => {
  const [state, dispatch] = useContext<FullContext>(AppContext);

  return (
    <Popup
      latitude={+selectedFacility.accountLat}
      longitude={+selectedFacility.accountLong}
      onClose={() => {
        setSelectedFacility(undefined);
      }}
    >
      <FacilityPopup>
        <p>
          <strong>Account Name: </strong>
          {selectedFacility.accountName}
        </p>
        <p>
          <strong>Account UCM ID: </strong>
          {selectedFacility.accountUCMID}
        </p>
        <p>
          <strong>Parent Account Name: </strong>
          {selectedFacility.parentName}
        </p>
        <p>
          <strong>City: </strong>
          {selectedFacility.accountCity}
        </p>
        <p>
          <strong>Postal Code: </strong>
          {selectedFacility.accountPrimaryPostal}
        </p>
        <p>
          <strong>Account Index: </strong>
          {selectedFacility.accountScore}
        </p>
        <p>
          <strong>Account Prioritization: </strong>
          {selectedFacility.accountPrioritization}
        </p>
        {isFacilityAlteredInCurrentScenario(
          state.active_scenario,
          selectedFacility
        ) && (
          <ScenarioChangeText>
            <b>New Territory (Scenario {state.active_scenario}): </b>
            {state.active_scenario === 1
              ? selectedFacility.scenarioOneCode
              : selectedFacility.scenarioTwoCode}
          </ScenarioChangeText>
        )}
      </FacilityPopup>
    </Popup>
  )
};
