import React, { useContext, useState } from 'react';
import { FullContext } from '../../shared/types';
import { AppContext } from '../../../app.store';
import { FormControl, MenuItem, Select } from '@mui/material';
import { SelectLabel } from '../styles';
import { reducerMethods } from '../../../app.reducer';

const ScenarioPlanningPanel = () => {
  const [state, dispatch] = useContext<FullContext>(AppContext);
  const [activeScenario, setActiveScenario] = useState(state.active_scenario);

  const onScenarioSelectChange = (scenario: number) => {
    setActiveScenario(scenario);
    dispatch({
      type: reducerMethods.setActiveScenario,
      payload: scenario,
    });
  };

  return (
    <FormControl fullWidth={true}>
      <SelectLabel>Active Scenario</SelectLabel>
      <Select
        name="activeScenarioSelect"
        value={activeScenario}
        onChange={(e) => onScenarioSelectChange(e.target.value as number)}
        displayEmpty={false}
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{ height: '40px' }}
      >
        <MenuItem value={0}>Default (No Scenario)</MenuItem>
        <MenuItem value={1}>Scenario One</MenuItem>
        <MenuItem value={2}>Scenario Two</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ScenarioPlanningPanel;
