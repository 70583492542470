import {
  FacilityDatagrid,
  FacilityFooter,
  FacilityTableContainer,
  TerritoryAssignmentDiv,
} from '../styles';
import {
  Button,
  FormControl,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useContext, useState } from 'react';
import { FullContext } from '../../shared/types';
import { AppContext } from '../../../app.store';
import { reducerMethods } from '../../../app.reducer';
import { Territory } from '@voiant/dataconnector';
import MenuItem from '@mui/material/MenuItem';
import { Api } from '../../../lib/api';
import {
  PostalCodeCodeAssignment,
  PostalCodeCodeAssignmentQueueMsg,
} from '../../../../../../dataprocessing/src/app';

export const TerritoryReassignmentPanel = ({
  clearMapboxSelection,
}: {
  clearMapboxSelection: () => void;
}) => {
  const [state, dispatch] = useContext<FullContext>(AppContext);
  const [targetTerritory, setTargetTerritory] = useState<string>(
    state.active_territories[0]?.accountFinalTerritoryCode ?? ''
  );
  const api = new Api(state.user);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const removeRegion = (postalCode: string) => {
    dispatch({
      type: reducerMethods.setSelectedPostalRegions,
      payload:
        state.selected_postal_regions?.filter(
          (region) => region.postalCode !== postalCode
        ) ?? [],
    });
  };

  const cancelForm = () => {
    clearMapboxSelection();
    dispatch({ type: reducerMethods.setSelectedPostalRegions, payload: [] });
  };

  const updateStateOfActiveTerritories = () => {
    const newTerritoryState = [...state.active_territories];
    const indexOfTargetTerritory = state.active_territories.findIndex(
      (territory) => territory.accountFinalTerritoryCode === targetTerritory
    );

    const regionsToUpdate =
      state.selected_postal_regions?.filter(
        (region) => region.territory !== targetTerritory
      ) ?? [];

    regionsToUpdate.forEach((selectedRegion) => {
      if (
        !newTerritoryState[indexOfTargetTerritory].postalCodes.find(
          (codepair) => codepair[0] === selectedRegion.featureId
        )
      ) {
        newTerritoryState[indexOfTargetTerritory].postalCodes.push([
          selectedRegion.featureId,
          selectedRegion.postalCode,
        ]);
      }
      const indexOfCurrentTerritory = newTerritoryState.findIndex(
        (territory) =>
          territory.accountFinalTerritoryCode === selectedRegion.territory
      );
      newTerritoryState[indexOfCurrentTerritory].postalCodes =
        newTerritoryState[indexOfCurrentTerritory].postalCodes.filter(
          (codePair) => codePair[1] !== selectedRegion.postalCode
        );
    });

    dispatch({
      type: reducerMethods.setActiveTerritories,
      payload: newTerritoryState,
    });
  };

  const submitForm = () => {
    const toPut: PostalCodeCodeAssignment[] =
      state.selected_postal_regions?.map((region) => {
        return {
          postalCodeCode: region.postalCodeCode,
          territoryCode: targetTerritory,
        };
      }) ?? [];

    const postalCodeUpdateQueue: PostalCodeCodeAssignmentQueueMsg = {
      source: state.mapFilterStatus.managerFilterSelections.source,
      items: toPut,
    };
    api.putTerritoriesForPostalCodes(postalCodeUpdateQueue).then(() => {
      updateStateOfActiveTerritories();
      dispatch({
        type: reducerMethods.setSelectedPostalRegions,
        payload: [],
      });
      clearMapboxSelection();
    });
  };

  const territoryAssignmentDropdown = () => {
    const onChangeTerritory = (e: SelectChangeEvent) => {
      setTargetTerritory(e.target.value);
    };

    return (
      <FormControl style={{ maxWidth: 220 }}>
        <Select
          inputProps={{ 'aria-label': 'Without label' }}
          displayEmpty
          onChange={(e) => onChangeTerritory(e)}
          value={targetTerritory}
          sx={{ height: '40px' }}
        >
          {state.active_territories &&
            state.active_territories.map((territory: Territory, i: number) => (
              <MenuItem key={i} value={territory.accountFinalTerritoryCode}>
                {territory.accountFinalTerritory}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  if (
    state.selected_postal_regions &&
    state.selected_postal_regions.length > 0
  ) {
    return (
      <FacilityTableContainer>
        <FacilityDatagrid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Postal Code</TableCell>
                  <TableCell align="right">
                    Current Territory Assignment
                  </TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {state.selected_postal_regions &&
                  state.selected_postal_regions.map((region, index: number) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="right">{region.postalCode}</TableCell>
                      <TableCell align="right">{region.territory}</TableCell>
                      <TableCell align="right">
                        <Button
                          onClick={() => removeRegion(region.postalCode)}
                          variant="outlined"
                          size="small"
                        >
                          Deselect
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FacilityDatagrid>
        <FacilityFooter>
          <TerritoryAssignmentDiv>
            <div>Assign to Territory:</div>
            {territoryAssignmentDropdown()}
          </TerritoryAssignmentDiv>
          <div>
            <Button variant="outlined" onClick={cancelForm}>
              Cancel
            </Button>
            <Button variant="contained" disabled={false} onClick={submitForm}>
              Save
            </Button>
          </div>
        </FacilityFooter>
      </FacilityTableContainer>
    );
  } else {
    return <></>;
  }
};
