import React, { useContext } from 'react';
import { FacilityPopup, ScenarioChangeText } from "../styles";
import { isFacilityAlteredInCurrentScenario } from "../markers";
import { Popup } from "react-map-gl";
import { FacilityData, FullContext } from "../../shared/types";
import { AppContext } from "../../../app.store";

export const AccountPopup = ({
   selectedFacility,
   setSelectedFacility
}: {
  selectedFacility: FacilityData;
  setSelectedFacility: (selectedFacility: FacilityData | undefined) => void;
}) => {
  const [state, dispatch] = useContext<FullContext>(AppContext);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Popup
      latitude={+selectedFacility.accountLat}
      longitude={+selectedFacility.accountLong}
      onClose={() => {
        setSelectedFacility(undefined);
      }}
    >
      <FacilityPopup>
        <p>
          <strong>Facility Name: </strong>
          {selectedFacility.accountName}
        </p>
        <p>
          <strong>Parent Name: </strong>
          {selectedFacility.parentName}
        </p>
        <p>
          <strong>UCM ID: </strong>
          {selectedFacility.accountUCMID}
        </p>
        <p>
          <strong>Territory Name: </strong>
          {selectedFacility.accountFinalTerritory}
        </p>
        <p>
          <strong>Account Score: </strong>
          {selectedFacility.accountScore}
        </p>
        <p>
          <strong>Current Funnel: </strong>
          {formatter.format(Number(selectedFacility.currentFunnel))}
        </p>
        <p>
          <strong>Historical Market (5 Yrs Avg): </strong>
          {formatter.format(selectedFacility.pastSales)}
        </p>
        <p>
          <strong>Total IB Count: </strong>
          {selectedFacility.IB_Count}
        </p>
        <p>
          <strong>Total IB Value: </strong>
          {selectedFacility.IB_Value}
        </p>
        {isFacilityAlteredInCurrentScenario(
          state.active_scenario,
          selectedFacility
        ) && (
          <ScenarioChangeText>
            <b>New Territory (Scenario {state.active_scenario}): </b>
            {state.active_scenario === 1
              ? selectedFacility.scenarioOneCode
              : selectedFacility.scenarioTwoCode}
          </ScenarioChangeText>
        )}
      </FacilityPopup>
    </Popup>
  )
};
