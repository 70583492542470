import { Api } from './lib/api';
import { FilterContext } from './components/shared/types';

export type Action = {
  type: reducerMethods;
  // Because of the way the reducer is used, this could literally be anything.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
};

export enum reducerMethods {
  setUser,
  setUserData,
  setSources,
  setRegions,
  setModalities,
  setSubmodalities,
  setZones,
  setActiveTerritories,
  setAllTerritories,
  setTerritory,
  setFacilities,
  setSelectedFacilities,
  setSelectedPostalRegions,
  setFacility,
  setActiveCountry,
  setFacilityPinColorSettings,
  setTerritoryManagerDisplayed,
  setTerritoryManagerFields,
  setNextList,
  setAdditionalFilterText,
  setAdditionalFilterAccountScore,
  setAdditionalFilterIbCount,
  setAdditionalFilterCurrentFunnel,
  setAdditionalFilterPastSalesRange,
  setAdditionalFilterTotalIbDollarValueRange,
  setAdditionalFilterFacilityType,
  setAdditionalFilterAccountPrioritization,
  setActiveScenario,
  setAllowRegionSelect,
  addLoad,
  completeLoad,
  setError,
  setMarkets,
  setShouldShowTerritoryDropdown,
  setShowTerritoryLabels,
  setSelectedTab,
  setParentName,
}

const AppReducer = (state: FilterContext, action: Action) => {
  let user;
  switch (action.type) {
    case reducerMethods.setUser:
      user = { ...state.user, ...action.payload };
      Api.user = user;
      return {
        ...state,
        user: user,
      };
    case reducerMethods.setUserData:
      user = { ...state.user, ...action.payload };
      Api.user = user;
      return {
        ...state,
        user,
      };
    case reducerMethods.setSources:
      return {
        ...state,
        sources: action.payload,
      };
    case reducerMethods.setRegions:
      return {
        ...state,
        regions: action.payload,
      };
    case reducerMethods.setModalities:
      return {
        ...state,
        modalities: action.payload,
      };
    case reducerMethods.setSubmodalities:
      return {
        ...state,
        submodalities: action.payload,
      };
    case reducerMethods.setZones:
      return {
        ...state,
        zones: action.payload,
      };
    case reducerMethods.setActiveTerritories:
      return {
        ...state,
        active_territories: action.payload,
      };
    case reducerMethods.setAllTerritories:
      return {
        ...state,
        all_territories: action.payload,
      };
    case reducerMethods.setTerritory:
      return {
        ...state,
        territory: action.payload,
      };
    case reducerMethods.setSelectedPostalRegions:
      return {
        ...state,
        selected_postal_regions: action.payload,
      };
    case reducerMethods.setFacilities:
      return {
        ...state,
        facilities: action.payload,
      };

    case reducerMethods.setSelectedFacilities:
      return {
        ...state,
        selected_facilities: action.payload,
      };

    case reducerMethods.setFacility:
      return {
        ...state,
        facility: action.payload,
      };

    case reducerMethods.setActiveCountry:
      return {
        ...state,
        active_country: action.payload,
      };

    case reducerMethods.setFacilityPinColorSettings:
      return {
        ...state,
        facilityPinColorSettings: action.payload,
      };

    case reducerMethods.setTerritoryManagerDisplayed:
      return {
        ...state,
        isManagerOpen: action.payload,
      };

    case reducerMethods.setTerritoryManagerFields:
      return {
        ...state,
        mapFilterStatus: {
          ...state.mapFilterStatus,
          managerFilterSelections: action.payload,
        },
      };

    case reducerMethods.setNextList:
      return {
        ...state,
        mapFilterStatus: {
          ...state.mapFilterStatus,
          nextList: action.payload,
        },
      };

    case reducerMethods.setAdditionalFilterText:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          textFilter: action.payload,
        },
      };

    case reducerMethods.setAdditionalFilterAccountScore:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          accountScoreRange: action.payload,
        },
      };

    case reducerMethods.setAdditionalFilterIbCount:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          ibCountRange: action.payload,
        },
      };

    case reducerMethods.setAdditionalFilterCurrentFunnel:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          currentFunnelRange: action.payload,
        },
      };

    case reducerMethods.setAdditionalFilterPastSalesRange:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          pastSalesRange: action.payload,
        },
      };

    case reducerMethods.setAdditionalFilterTotalIbDollarValueRange:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          totalIBDollarValueRange: action.payload,
        },
      };

    case reducerMethods.setAdditionalFilterFacilityType:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          facilityTypeFilter: action.payload,
        },
      };

    case reducerMethods.setAdditionalFilterAccountPrioritization:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          accountPrioritizationFilter: action.payload,
        },
      };

    case reducerMethods.setActiveScenario:
      return {
        ...state,
        active_scenario: action.payload,
      };

    case reducerMethods.setAllowRegionSelect:
      return {
        ...state,
        allowRegionSelect: action.payload,
      };

    case reducerMethods.addLoad:
      return {
        ...state,
        isLoading: state.isLoading + 1,
      };

    case reducerMethods.completeLoad:
      return {
        ...state,
        isLoading: state.isLoading - 1,
      };

    case reducerMethods.setMarkets:
      return {
        ...state,
        markets: action.payload,
      };

    case reducerMethods.setShouldShowTerritoryDropdown:
      return {
        ...state,
        shouldShowTerritoryDropdown: action.payload,
      };
    case reducerMethods.setShowTerritoryLabels:
      return {
        ...state,
        showTerritoryLabels: action.payload,
      };

    case reducerMethods.setSelectedTab:
      return {
        ...state,
        selectedTab: action.payload,
      };

    case reducerMethods.setParentName:
      return {
        ...state,
        parentName: action.payload,
      };

    case reducerMethods.setError:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default AppReducer;
