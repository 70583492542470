export const emeaAccountScoreRanges: {[key: string]: string} = {
  'High: 80-100': '#1aff00',
  'Med-High: 60-79': 'rgba(139,255,128,0.8)',
  'Med: 40-59': '#e5e346',
  'Med-Low: 20-39': '#fa9e42',
  'Low: 0-19': '#e03232',
}

export const uscanAccountScoreRanges: {[key: string]: string} = {
  'High: 50+': '#1aff00',
  'Med-High: 26-50': 'rgba(139,255,128,0.8)',
  'Med: 11-25': '#e5e346',
  'Med-Low: 6-10': '#fa9e42',
  'Low: 0-5': '#e03232',
  'None: 0': '#505050'
}
