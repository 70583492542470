export const facilityTypeColors: {[key: string]: string} = {
  "Financial Institution": "#483FC6",
  "Dealer": "#993fc6",
  "Strategic Health Authority": "#c63f77",
  "Academic Institution": "#3f9dc6",
  "Group Practice": "#c63f3f",
  "Womens Healthcare Center": "#3fc68e",
  "Group Purchasing Organization": "#85c63f",
  "Single Practitioner": "#c6b03f",
  "Veterinary Clinic/Hospital": "#c67c3f",
  "Sub Dealer": "#c6443f",
  "Pharmacy": "#9986F1",
  "3rd Party Service": "#0F3D41",
  "Clinic": "#F57C3D",
  "Diagnostic Imaging Center": "#A6647C",
  "Hospital": "#E2EC7D",
  "Other Business": "#3782AF"
}
