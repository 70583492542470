import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { facilityTypeColors } from '../../../../../../../libs/dataconnector/src/lib/facilityTypeColors';
import { Facility } from '@voiant/dataconnector';
import { FullContext } from '../../shared/types';
import { AppContext } from '../../../app.store';
import {
  AccordionSection,
  ColorLegend,
  SelectLabel,
  SwitchLabel,
  switchSx,
} from '../styles';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { emeaAccountScoreRanges, uscanAccountScoreRanges } from '../../../../assets/customization/accountScoreRanges';
import { reducerMethods } from '../../../app.reducer';
import { AccountFilters as EmeaAccountFilters } from './emea/accountFilters';
import { AccountFilters as UscanAccountFilters } from './uscan/accountFilters';

export default function ConfigurationsPanel({
  isDisabled,
  isAdditionalExpanded,
  setIsAdditionalExpanded,
  unfilteredFacilities,
  isMapSettingsExpanded,
  setIsMapSettingsExpanded,
}: {
  isDisabled: boolean;
  isAdditionalExpanded: boolean;
  setIsAdditionalExpanded: (isAdditionalExpanded: boolean) => void;
  unfilteredFacilities: Facility[];
  isMapSettingsExpanded: boolean;
  setIsMapSettingsExpanded: (isMapSettingsExpanded: boolean) => void;
}) {
  const [state, dispatch] = useContext<FullContext>(AppContext);
  const selectedSource = state.mapFilterStatus.managerFilterSelections.source;
  const accountScoreRange = selectedSource === 'USCAN' ? uscanAccountScoreRanges : emeaAccountScoreRanges;

  const accountScoreLabel =
    selectedSource === 'USCAN' ? 'Account Index' : 'Account Score';

  const [colorSetting, setColorSetting] = useState<number>(
    state.facilityPinColorSettings
  );
  const [allowSelectRegion, setAllowSelectRegion] = useState<boolean>(
    state.allowRegionSelect
  );
  const [showTerritoryLabels, setShowTerritoryLabels] = useState<boolean>(
    state.showTerritoryLabels
  );

  useEffect(() => {
    dispatch({
      type: reducerMethods.setFacilityPinColorSettings,
      payload: colorSetting,
    });
  }, [colorSetting]);

  useEffect(() => {
    dispatch({
      type: reducerMethods.setAllowRegionSelect,
      payload: allowSelectRegion,
    });
  }, [allowSelectRegion]);

  useEffect(() => {
    dispatch({
      type: reducerMethods.setShowTerritoryLabels,
      payload: showTerritoryLabels,
    });
  }, [showTerritoryLabels]);

  function renderColorSettingLegend() {
    if (colorSetting === 1) {
      return (
        <ColorLegend>
          <List>
            <Typography sx={{ fontWeight: 'bold' }}>Facility Type</Typography>
            {Object.entries(facilityTypeColors).map(([key, value]) => (
              <ListItem key={key}>
                <ListItemIcon>
                  <SquareRoundedIcon style={{ color: value }} />
                </ListItemIcon>
                <ListItemText primary={key} />
              </ListItem>
            ))}
          </List>
        </ColorLegend>
      );
    } else if (colorSetting === 2) {
      return (
        <ColorLegend>
          <List>
            <Typography sx={{ fontWeight: 'bold' }}>
              {accountScoreLabel}
            </Typography>
            {Object.entries(accountScoreRange).map(([key, value]) => (
              <ListItem key={key}>
                <ListItemIcon>
                  <SquareRoundedIcon style={{ color: value }} />
                </ListItemIcon>
                <ListItemText primary={key} />
              </ListItem>
            ))}
          </List>
        </ColorLegend>
      );
    } else {
      return;
    }
  }

  return (
    <>
      <Accordion
        disabled={isDisabled}
        expanded={isMapSettingsExpanded}
        onChange={() => setIsMapSettingsExpanded(!isMapSettingsExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Map Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionSection>
            <FormControl fullWidth={true}>
              <SelectLabel>Color Mode</SelectLabel>
              <Select
                name="colorSetting"
                value={colorSetting}
                onChange={(e) => setColorSetting(Number(e.target.value))}
                displayEmpty={true}
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ height: '40px' }}
              >
                <MenuItem value={0}>Territory (default)</MenuItem>
                <MenuItem value={1}>Facility Type</MenuItem>
                <MenuItem value={2}>{accountScoreLabel}</MenuItem>
              </Select>
            </FormControl>
          </AccordionSection>
          {state.selectedTab !== 1 && (
            <AccordionSection>
              <FormControl fullWidth={true} sx={switchSx}>
                <SwitchLabel>Region Selection</SwitchLabel>
                <Switch
                  checked={allowSelectRegion}
                  onChange={(e) => setAllowSelectRegion(e.target.checked)}
                  value={allowSelectRegion}
                />
              </FormControl>
            </AccordionSection>
          )}
          <AccordionSection>
            <FormControl fullWidth={true} sx={switchSx}>
              <SwitchLabel> Territory Labels</SwitchLabel>
              <Switch
                checked={showTerritoryLabels}
                onChange={(e) => setShowTerritoryLabels(e.target.checked)}
                value={showTerritoryLabels}
              />
            </FormControl>
          </AccordionSection>
          {renderColorSettingLegend()}
        </AccordionDetails>
      </Accordion>

      {/*Render Account Filters*/}
      {selectedSource === 'USCAN' ? (
        <UscanAccountFilters
          isDisabled={isDisabled}
          isAdditionalExpanded={isAdditionalExpanded}
          setIsAdditionalExpanded={setIsAdditionalExpanded}
          unfilteredFacilities={unfilteredFacilities}
        />
      ) : (
        <EmeaAccountFilters
          isDisabled={isDisabled}
          isAdditionalExpanded={isAdditionalExpanded}
          setIsAdditionalExpanded={setIsAdditionalExpanded}
          unfilteredFacilities={unfilteredFacilities}
        />
      )}
    </>
  );
}
