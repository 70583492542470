import styled from '@emotion/styled';

export const NavPanelForm = styled.form`
  text-align: center;
  max-width: 600px;
  margin: 10px 0;
  overflow-x: hidden;
  box-shadow: 4px 4px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;

  fieldset {
    legend {
      width: unset;
    }
  }
`;

export const ManagerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ColorLegend = styled.div`
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 6px;
  width: 220px;
  max-height: 300px;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll;
`;

export const LoaderDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

export const SelectLabel = styled.div`
  margin-bottom: 0.5rem;
`;

export const SwitchLabel = styled.div``;

export const AccordionSection = styled.div`
  margin: 10px 0 20px 0;
`
export const switchSx = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
};
