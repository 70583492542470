import { MapEvent } from 'react-map-gl';
import * as turf from '@turf/turf';
import { Feature, Polygon, Properties } from '@turf/turf';
import { FacilityData, PostalRegion } from '../shared/types';
import { Map as MapboxMap, MapboxGeoJSONFeature } from 'mapbox-gl';
import { Territory } from '@voiant/dataconnector';

function getFacilitiesForRegion(
  searchRegion: Polygon,
  facilities: FacilityData[] | undefined
) {
  const facilityPoints = getFacilityPoints(facilities);
  if (!facilityPoints) {
    return [];
  } else {
    return turf
      .pointsWithinPolygon(facilityPoints, searchRegion)
      .features.map((feature) => {
        return feature.properties;
      });
  }
}

export function selectRegion(
  event: MapEvent,
  facilities: FacilityData[] | undefined
) {
  const anaplanZonesForClick = getTerritoriesForEvent(event);
  if (anaplanZonesForClick.length > 0) {
    return getFacilitiesForRegion(anaplanZonesForClick[0].geometry, facilities);
  } else {
    return [];
  }
}

export function getTerritoriesForEvent(e: MapEvent) {
  return (
    e.features?.filter(
      (feat) => feat.layer.id === 'pos4-join' || feat.layer.id === 'pos2-join'
    ) ?? []
  );
}

export function getTerritoriesForPoint(
  latLng: [number, number],
  map: MapboxMap
) {
  return map.queryRenderedFeatures(map.project(latLng), {
    layers: [map.getLayer('pos4-join') ? 'pos4-join' : 'pos2-join'],
  });
}

export function getFacilityPoints(facilities: FacilityData[] | undefined) {
  if (facilities) {
    return turf.featureCollection(
      facilities.map((facility) => {
        return turf.point(
          [Number(facility.accountLong), Number(facility.accountLat)],
          {
            ...facility,
          }
        );
      })
    );
  } else {
    return undefined;
  }
}

export function getIntersectingRegions(
  searchZone: Feature<Polygon, Properties>,
  map: MapboxMap
) {
  const bbox = turf.bbox(searchZone);
  const selectedFeatures = map.queryRenderedFeatures(
    [map.project([bbox[0], bbox[1]]), map.project([bbox[2], bbox[3]])],
    {
      layers: [map.getLayer('pos4-join') ? 'pos4-join' : 'pos2-join'],
    }
  );
  const filteredFeatures = selectedFeatures
    .filter((feature) => feature.geometry.type === 'Polygon')
    .reduce((intersectedFeatures: MapboxGeoJSONFeature[], nextFeature) => {
      if (turf.intersect(nextFeature.geometry as Polygon, searchZone)) {
        intersectedFeatures.push(nextFeature);
      }
      return intersectedFeatures;
    }, []);

  return filteredFeatures;
}

export function getRegionDataFromFeatures(
  territoryInfo: Territory[],
  retrievedFeatures: mapboxgl.MapboxGeoJSONFeature[],
  facilities: FacilityData[]
) {
  const regionData: PostalRegion[] = [];
  if (territoryInfo) {
    retrievedFeatures.forEach((feature) => {
      const territory = territoryInfo.find(
        (territory) =>
          feature.state.territory === territory.accountFinalTerritoryCode
      );
      const facilitiesInPostalRegion = getFacilitiesForRegion(
        feature.geometry as Polygon,
        facilities
      );
      const postal =
        territory?.postalCodes.find((code) => code[0] === feature.id)[1] ?? '';
      const postalInfo = territory?.postalCodes.find(
        (codeCode) => codeCode[1] === postal
      );
      regionData.push({
        facilities: facilitiesInPostalRegion,
        postalCode: postal,
        territory: territory?.accountFinalTerritoryCode ?? '',
        featureId: Number(feature.id),
        postalCodeCode: postalInfo ? postalInfo[3] : '',
      });
    });
  }
  return regionData;
}

export function getPostalRegionDataForRegion(
  searchZone: Feature<Polygon, Properties>,
  map: MapboxMap,
  territoryInfo: Territory[],
  facilities: FacilityData[]
): PostalRegion[] {
  const retrievedFeatures = getIntersectingRegions(searchZone, map);
  return getRegionDataFromFeatures(
    territoryInfo,
    retrievedFeatures,
    facilities
  );
}
