import {
  Button,
  FormControl,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Facility as ModelFacility, Territory } from '@voiant/dataconnector';
import { Api } from '../../../lib/api';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../app.store';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ScenarioAssignment, TerritoryAssignment } from '../../../../../../dataprocessing/src/app';
import MenuItem from '@mui/material/MenuItem';
import { FullContext } from '../../shared/types';
import { FacilityDatagrid, FacilityFooter, FacilityTableContainer, TerritoryAssignmentDiv } from '../styles';
import { reducerMethods } from '../../../app.reducer';

/* eslint-disable-next-line */
export interface FacilityProps {
  selectedFacilities?: Array<ModelFacility>;
  className?: string;
  clearMapboxSelection: () => void;
}

export interface FacilityRow {
  accountCode: string;
  accountName: string;
  accountUCMID: string;
  parentName: string;
  accountFinalTerritoryCode: string;
  accountScore: number;
  scenarioOneCode: string;
  scenarioTwoCode: string;
  index: string;
}

export function FacilityReassignmentPanel(props: FacilityProps) {
  const [state, dispatch] = useContext<FullContext>(AppContext);
  const [territoryData, setTerritoryData] = useState<
    Array<TerritoryAssignment>
    >([]);
  const [scenarioData, setScenarioData] = useState<Array<ScenarioAssignment>>();
  const [isSingleDropdownDisabled, setIsSingleDropdownDisabled] =
    useState<boolean>(false);
  const [isBulkDropdownDisabled, setIsBulkDropdownDisabled] =
    useState<boolean>(false);
  const api = new Api(state.user);
  const [facilities, setFacilities] = useState<Array<FacilityRow>>([]);

  const onChangeTerritoryForSingleFacility = (e: any) => {
    setIsBulkDropdownDisabled(true);
    const facility: TerritoryAssignment = {
      accountCode: e.target.name,
      territoryId: e.target.value,
    };
    const updatedTerritoryData = territoryData.filter(
      (assignment) => assignment.accountCode !== facility.accountCode
    );
    if (
      facilities.find(
        (origFacility) =>
          origFacility.accountCode === facility.accountCode &&
          origFacility.accountFinalTerritoryCode === facility.territoryId
      )
    ) {
      setTerritoryData(updatedTerritoryData);
    } else {
      setTerritoryData([...updatedTerritoryData, facility]);
    }
  };

  const onChangeTerritory = (e: any) => {
    setIsSingleDropdownDisabled(true);
    let updatedFacilities;

    if (state.active_scenario === 0) {
      updatedFacilities = facilities.map((facility) => {
        return {
          accountCode: facility.accountCode,
          territoryId: e.target.value,
        };
      });
      setTerritoryData(updatedFacilities);
    } else {
      if (state.active_scenario === 1) {
        updatedFacilities = facilities.map((facility) => {
          return {
            accountCode: facility.accountCode,
            scenarioOneCode: e.target.value,
            scenarioTwoCode: facility.scenarioTwoCode,
          };
        });
      } else if (state.active_scenario === 2) {
        updatedFacilities = facilities.map((facility) => {
          return {
            accountCode: facility.accountCode,
            scenarioOneCode: facility.scenarioOneCode,
            scenarioTwoCode: e.target.value,
          };
        });
      }
      setScenarioData(updatedFacilities);
    }
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    console.log('state.selected_facilities', state.selected_facilities);
    if (state.selected_facilities && state.selected_facilities.length > 0) {
      const rows = state.selected_facilities.map(
        (val: ModelFacility, index: number) => {
          const {
            accountCode,
            accountName,
            accountUCMID,
            parentName,
            accountFinalTerritoryCode,
            accountScore,
            scenarioOneCode,
            scenarioTwoCode,
          } = val;
          return createData(
            accountCode,
            accountName,
            accountUCMID,
            parentName,
            accountFinalTerritoryCode,
            accountScore,
            scenarioOneCode,
            scenarioTwoCode,
            'Name_' + index
          );
        }
      );
      setFacilities(rows);
    } else {
      setFacilities([]);
      setScenarioData([]);
      setIsSingleDropdownDisabled(false);
      setIsBulkDropdownDisabled(false);
      props.clearMapboxSelection();
    }
  }, [state.selected_facilities]);

  const territoryDropdown = (facility: FacilityRow) => {
    return (
      <FormControl style={{ maxWidth: 220 }}>
        <Select
          name={facility.accountCode}
          inputProps={{ 'aria-label': 'Without label' }}
          displayEmpty
          onChange={(e) => onChangeTerritoryForSingleFacility(e)}
          disabled={
            state.user?.role !== 'READWRITE' || isSingleDropdownDisabled
          }
          defaultValue={facility.accountFinalTerritoryCode}
        >
          {state.active_territories &&
            state.active_territories.map((territory: Territory, i: number) => (
              <MenuItem key={i} value={territory.accountFinalTerritoryCode}>
                {territory.accountFinalTerritory}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  const territoryDropdownBulk = (facilities: FacilityRow[]) => {
    return (
      <FormControl style={{ maxWidth: 220 }}>
        <Select
          name={facilities[0].accountFinalTerritoryCode}
          inputProps={{ 'aria-label': 'Without label' }}
          displayEmpty
          onChange={(e) => onChangeTerritory(e)}
          disabled={state.user?.role !== 'READWRITE' || isBulkDropdownDisabled}
          defaultValue={
            state.active_scenario === 0
              ? facilities[0].accountFinalTerritoryCode
              : state.active_scenario === 1
                ? facilities[0].scenarioOneCode
                : facilities[0].scenarioTwoCode
          }
          sx={{ height: '40px' }}
        >
          {state.active_territories &&
            state.active_territories.map((territory: Territory, i: number) => (
              <MenuItem key={i} value={territory.accountFinalTerritoryCode}>
                {territory.accountFinalTerritory}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  function createData(
    accountCode: string,
    accountName: string,
    accountUCMID: string,
    parentName: string,
    accountFinalTerritoryCode: string,
    accountScore: number,
    scenarioOneCode: string,
    scenarioTwoCode: string,
    index: string
  ): FacilityRow {
    return {
      accountCode,
      accountName,
      accountUCMID,
      parentName,
      accountFinalTerritoryCode,
      accountScore,
      scenarioOneCode,
      scenarioTwoCode,
      index,
    };
  }

  function cancelForm() {
    setFacilities([]);
    setScenarioData([]);
    setIsSingleDropdownDisabled(false);
    setIsBulkDropdownDisabled(false);
    props.clearMapboxSelection();
    dispatch({ type: reducerMethods.setSelectedFacilities, payload: null });
  }

  function removeFacility(accountCode: string) {
    const filteredFacilities = facilities?.filter(
      (facility) => facility.accountCode !== accountCode.toString()
    );
    setFacilities(filteredFacilities);
    dispatch({
      type: reducerMethods.setSelectedFacilities,
      payload: filteredFacilities,
    });
  }

  function submitForm() {
    console.log('submitForm', territoryData);
    if (territoryData && state.active_scenario === 0) {
      const territories = {
        source: state.mapFilterStatus.managerFilterSelections.source,
        items: territoryData
      }

      api.putTerritoriesForFacilities(territories).then(() => {
        api
          .getFacilitiesForTerritory(
            state.territory ?? '',
            state.mapFilterStatus.managerFilterSelections.source
          ).then((facilities) => {
            console.log('faciliites', facilities);
            dispatch({
              type: reducerMethods.setFacilities,
              payload: facilities,
            });
            setTerritoryData([]);
            setScenarioData([]);
          })
          .catch((err) => {
            console.log(err);
          });
        setFacilities([]);
        setIsSingleDropdownDisabled(false);
        setIsBulkDropdownDisabled(false);
      });
    }

    if (
      scenarioData &&
      (state.active_scenario === 1 || state.active_scenario === 2)
    ) {
      const scenarios = {
        source: state.mapFilterStatus.managerFilterSelections.source,
        items: scenarioData,
      };

      api.putScenariosForFacilities(scenarios).then(() => {
        api
          .getFacilitiesForTerritory(
            state.territory ?? '',
            state.mapFilterStatus.managerFilterSelections.source
          ).then((facilities) => {
            console.log('faciliites', facilities);
            dispatch({
              type: reducerMethods.setFacilities,
              payload: facilities,
            });
            setTerritoryData([]);
            setScenarioData([]);
          })
          .catch((err) => {
            console.log(err);
          });
        setFacilities([]);
        setIsSingleDropdownDisabled(false);
        setIsBulkDropdownDisabled(false);
      });
    }
  }

  if (facilities && facilities.length > 0) {
    const territoryName =
      state.active_scenario === 0
        ? 'Territory Name'
        : state.active_scenario === 1
          ? 'Scenario One'
          : 'Scenario Two';

    return (
      <FacilityTableContainer>
        <FacilityDatagrid>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell align="right">Facility Name</TableCell>
                  <TableCell align="right">UCM ID</TableCell>
                  <TableCell align="right">Parent Name</TableCell>
                  <TableCell align="right">{territoryName}</TableCell>
                  <TableCell align="right">Account Index</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {facilities &&
                  facilities.map((row: FacilityRow) => (
                    <TableRow
                      key={row.index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="right">{row.accountName}</TableCell>
                      <TableCell align="right">{row.accountUCMID}</TableCell>
                      <TableCell align="right">{row.parentName}</TableCell>
                      <TableCell align="right">
                        {state.active_scenario === 0 && territoryDropdown(row)}
                        {state.active_scenario === 1 && row.scenarioOneCode}
                        {state.active_scenario === 2 && row.scenarioTwoCode}
                      </TableCell>
                      <TableCell align="right">{row.accountScore}</TableCell>
                      <TableCell align="right">
                        <Button
                          onClick={() => removeFacility(row.accountCode)}
                          variant="outlined"
                          size="small"
                        >
                          UnSelect
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FacilityDatagrid>
        <FacilityFooter>
          <TerritoryAssignmentDiv>
            <div>Assign to Territory:</div>
            {territoryDropdownBulk(facilities)}
          </TerritoryAssignmentDiv>
          <div>
            <Button variant="outlined" onClick={cancelForm}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={
                (!territoryData || territoryData.length < 1) &&
                (!scenarioData || scenarioData.length < 1)
              }
              onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </FacilityFooter>
      </FacilityTableContainer>
    );
  } else {
    return null;
  }
};
