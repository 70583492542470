import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { useContext, useEffect } from 'react';
import { AppContext } from '../../app.store';
import Main from '../main/main';
import { MainLayout } from './styles';
import { Auth } from 'aws-amplify';
import { FullContext } from '../../components/shared/types';
import { reducerMethods } from '../../app.reducer';

export function Layout() {
  const [state, dispatch] = useContext<FullContext>(AppContext);

  useEffect(() => {
    Auth.currentUserInfo()
      .then((user) => {
        dispatch({
          type: reducerMethods.setUser,
          payload: user,
        });
      })
      .catch((err) => {
        console.log('Problem with user - ');
        console.error(err);
        alert(
          'We were unable to load your user record. Please check with support'
        );
      });
  }, []);

  return (
    <Router>
      <MainLayout>
        <Switch>
          <Route path="/main">
            <Main />
          </Route>
          <Route path="/">
            <Redirect to="/main" />
          </Route>
        </Switch>
      </MainLayout>
    </Router>
  );
}

export default Layout;
