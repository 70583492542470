import { ChildView, MainComponent } from './styles';
import MapBox from '../../components/map-box/map-box';
import NavPanel from '../../components/nav-panel/nav-panel';
import ToolBar from '../../components/toolbar/toolbar';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app.store';
import { Api } from '../../lib/api';
import { Auth } from 'aws-amplify';
import { FullContext } from '../../components/shared/types';
import { reducerMethods } from '../../app.reducer';

export function Main() {
  const [state, dispatch] = useContext<FullContext>(AppContext);
  const [userLoaded, setUserLoaded] = useState<boolean>(false);

  useEffect(() => {
    Auth.currentUserInfo().then((ampUser) => {
      const api = new Api(ampUser);

      api
        .getUser()
        .then((data) => {
          dispatch({ type: reducerMethods.setUserData, payload: data });
          setUserLoaded(true);
        })
        .catch(() => {
          console.log('fetch error');
        });
    });
  }, []);

  return (
    <>
      {userLoaded && state && state.user && (
        <MainComponent>
          <ToolBar />
          <ChildView>
            <MapBox />
            {state && state.user && state.user.userEmail && <NavPanel />}
          </ChildView>
        </MainComponent>
      )}
    </>
  );
}

export default Main;
