import { ColorSettings, FacilityData, FilterContext } from '../shared/types';
import React from 'react';
import { LngLat, Map as MapboxMap, Point } from 'mapbox-gl';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import { Marker } from 'react-map-gl';
import tinycolor from 'tinycolor2';

const markerSize = 17;
const MIN_AGGREGATION_LIMIT = 2000;

type MapPoint = {
  facilities: FacilityData[];
  lngLat: LngLat;
  mapXY: Point;
  hasAlteredFacility: boolean;
};

export const isFacilityAlteredInCurrentScenario = (
  activeScenario: number,
  facility: FacilityData
) => {
  const currentScenarioCode =
    activeScenario === 1 ? facility.scenarioOneCode : facility.scenarioTwoCode;
  return (
    activeScenario > 0 &&
    currentScenarioCode !== facility.accountFinalTerritoryCode
  );
};

const isValidLatLong = (lat: number, long: number) => {
  return (
    lat !== null &&
    long !== null &&
    lat !== undefined &&
    long !== undefined &&
    lat <= 90 &&
    lat >= -90 &&
    long <= 180 &&
    long >= -180
  );
};

const getCombinedLocations = (
  map: MapboxMap,
  facilities: FacilityData[],
  activeScenario: number
) => {
  const locations: MapPoint[] = [];
  facilities.forEach((facility) => {
    const mapPoint = map.project([
      Number(facility.accountLong),
      Number(facility.accountLat),
    ]);
    let wasAdded = false;
    locations.every((existingMarker) => {
      if (
        !isFacilityAlteredInCurrentScenario(activeScenario, facility) &&
        !existingMarker.hasAlteredFacility &&
        markerSize * 1.5 > mapPoint.dist(existingMarker.mapXY)
      ) {
        existingMarker.facilities.push(facility);
        wasAdded = true;
        return false;
      } else {
        return true;
      }
    });
    if (!wasAdded) {
      locations.push({
        facilities: [facility],
        lngLat: new LngLat(
          Number(facility.accountLong),
          Number(facility.accountLat)
        ),
        mapXY: mapPoint,
        hasAlteredFacility: isFacilityAlteredInCurrentScenario(
          activeScenario,
          facility
        ),
      });
    }
  });
  return locations;
};

const getMarkerColor = (
  state: FilterContext,
  colors: {
    [key: string]: string;
  },
  facilities: FacilityData[]
): string => {
  let color = '#FFFFFF';

  const referenceFacility = facilities[0];

  switch (state.facilityPinColorSettings) {
    case ColorSettings.territory:
      if (
        isFacilityAlteredInCurrentScenario(
          state.active_scenario,
          referenceFacility
        )
      ) {
        color =
          colors[
            state.active_scenario === 1
              ? referenceFacility.scenarioOneCode
              : referenceFacility.scenarioTwoCode
          ];
      } else {
        color = colors[referenceFacility.accountFinalTerritoryCode];
      }
      break;
    case ColorSettings.facilityType:
      if (
        facilities.every(
          (facility) => facility.facilityType === referenceFacility.facilityType
        )
      ) {
        color = referenceFacility.facilityTypeColor;
      }
      break;
    case ColorSettings.accountScore:
      if (facilities.length === 1) {
        color = referenceFacility.accountScoreColor;
      }
      break;
    default:
      break;
  }

  return color || '#00ff00';
};

const facilityToDataPoint = (
  map: MapboxMap,
  facility: FacilityData,
  activeScenario: number
): MapPoint => {
  return {
    hasAlteredFacility: isFacilityAlteredInCurrentScenario(
      activeScenario,
      facility
    ),
    mapXY: map.project([
      Number(facility.accountLong),
      Number(facility.accountLat),
    ]),
    facilities: [facility],
    lngLat: new LngLat(
      Number(facility.accountLong),
      Number(facility.accountLat)
    ),
  };
};

function getMarkerElements(
  aggregatedMarkerMapPoints: MapPoint[],
  state: FilterContext,
  territoryColors: { [p: string]: string },
  pinSize: number,
  facilityOnClick: (facility: FacilityData) => void,
  facilityMouseOver: (
    facility: FacilityData,
    e: React.MouseEvent<Element>
  ) => void,
  facilityMouseOut: () => void
) {
  return aggregatedMarkerMapPoints.map((location: MapPoint, key) => {
    const useAlteredPin = location.hasAlteredFacility;
    const fillColor = getMarkerColor(
      state,
      territoryColors,
      location.facilities
    );
    return (
      <Marker
        key={key}
        longitude={location.lngLat.lng}
        latitude={location.lngLat.lat}
        offsetLeft={useAlteredPin ? -1 * (pinSize / 2) : -7}
        offsetTop={useAlteredPin ? -1 * pinSize : -12}
      >
        {useAlteredPin && (
          <EditLocationAltIcon
            sx={{
              color: fillColor,
              fontSize: pinSize,
              stroke: 'black',
              strokeWidth: 1,
              cursor: 'pointer',
            }}
            onClick={() => facilityOnClick(location.facilities[0])}
            onMouseOver={(e) => facilityMouseOver(location.facilities[0], e)}
            onMouseOut={facilityMouseOut}
          />
        )}
        {!useAlteredPin && (
          <button
            style={{
              backgroundColor: fillColor,
              height: location.facilities.length === 1 ? markerSize : 'auto',
              width: location.facilities.length === 1 ? markerSize : 'auto',
              borderRadius: markerSize,
              borderColor: 'black',
              borderStyle: 'solid',
              borderWidth: 1,
              color: tinycolor(fillColor).isLight() ? 'black' : 'white',
            }}
            onMouseOver={
              location.facilities.length === 1
                ? (e) => facilityMouseOver(location.facilities[0], e)
                : () => undefined
            }
            onMouseOut={facilityMouseOut}
            onClick={
              location.facilities.length === 1
                ? () => facilityOnClick(location.facilities[0])
                : () => undefined
            }
          >
            {location.facilities.length > 1 ? location.facilities.length : ''}
          </button>
        )}
      </Marker>
    );
  });
}

export function getMarkers(
  map: MapboxMap,
  state: FilterContext,
  territoryColors: {
    [key: string]: string;
  },
  activeFacilities: FacilityData[],
  selectedFacilities: FacilityData[],
  setSelectedFacilities: (
    value: ((prevState: FacilityData[]) => FacilityData[]) | FacilityData[]
  ) => void,
  setSelectedFacility: (value: FacilityData | undefined) => void
) {
  if (state.facilities && state.facilities.length > 0) {
    const facilityOnClick = (facility: FacilityData) => {
      const selected = selectedFacilities || [];
      selected.push(facility);
      setSelectedFacilities(selected);
    };

    const facilityMouseOver = (
      facility: FacilityData,
      e: React.MouseEvent<Element>
    ) => {
      const btn: any = e.currentTarget;
      btn.style.zIndex = '-1';
      setSelectedFacility(facility);
    };

    const facilityMouseOut = () => {
      setSelectedFacility(undefined);
    };

    const pinSize = 24;

    const validFacilities = activeFacilities.filter((facility) =>
      isValidLatLong(Number(facility.accountLat), Number(facility.accountLong))
    );

    const aggregatedMarkerMapPoints =
      activeFacilities.length > MIN_AGGREGATION_LIMIT
        ? getCombinedLocations(map, validFacilities, state.active_scenario)
        : validFacilities.map((facility) =>
            facilityToDataPoint(map, facility, state.active_scenario)
          );

    return getMarkerElements(
      aggregatedMarkerMapPoints,
      state,
      territoryColors,
      pinSize,
      facilityOnClick,
      facilityMouseOver,
      facilityMouseOut
    );
  } else {
    setSelectedFacility(undefined);
    setSelectedFacilities([]);
    return null;
  }
}
