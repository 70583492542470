// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".map-box_navControlStyle__2SjqZ {\n  position: absolute;\n  right: 10px;\n  top: 10px;\n}", ""]);
// Exports
exports.locals = {
	"navControlStyle": "map-box_navControlStyle__2SjqZ"
};
module.exports = exports;
