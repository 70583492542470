import styled from '@emotion/styled';

export const MainComponent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ChildView = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
