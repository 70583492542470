import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useFormFields } from '../../../lib/formFieldHook';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Api, ListType } from '../../../lib/api';
import { Facility, OptionEntry, Territory } from '@voiant/dataconnector';
import Loading from '../loading';
import { FullContext, SelectOption } from '../../shared/types';
import { AppContext } from '../../../app.store';
import { reducerMethods } from '../../../app.reducer';
import { SelectLabel } from '../styles';

enum USCANPanelTabs {
  TerritorySearch,
  ParentSearch,
}

export default function TerritoryPanel({
  setIsAdditionalExpanded,
  setIsMapSettingsExpanded,
  setUnfilteredFacilities,
  setIsDisabled,
}: {
  setIsAdditionalExpanded: (isAdditionalExpanded: boolean) => void;
  setIsMapSettingsExpanded: (isMapSettingsExpanded: boolean) => void;
  setUnfilteredFacilities: (unfilteredFacilities: Facility[]) => void;
  setIsDisabled: (isDisabled: boolean) => void;
}) {
  const [state, dispatch] = useContext<FullContext>(AppContext);
  const api = new Api(state.user);
  const [fields, handleFieldChange] = useFormFields(
    state.mapFilterStatus.managerFilterSelections
  );
  const [nextList, setNextList] = useState<ListType>(
    state.mapFilterStatus.nextList
  );
  const [listChange, setListChange] = useState<boolean>(false);
  const [facilitiesLoaded, setFacilitiesLoaded] = useState<number>(0);
  const [totalFacilities, setTotalFacilities] = useState<number>(0);
  const [isEmea, setEmea] = useState<boolean>(
    state.mapFilterStatus.managerFilterSelections.source === 'EMEA'
  );
  const [isPdx, setPdx] = useState<boolean>(
    state.mapFilterStatus.managerFilterSelections.source === 'PDx'
  );
  const [isUsCan, setIsUsCan] = useState<boolean>(
    state.mapFilterStatus.managerFilterSelections.source === 'USCAN'
  );

  const [selectedTab, setSelectedTab] = useState<number>(state.selectedTab);
  const [parentName, setParentName] = useState<string>(state.parentName);

  const isLoad = useRef(true);

  useEffect(() => {
    dispatch({
      type: reducerMethods.setTerritoryManagerFields,
      payload: fields,
    });
  }, [fields]);

  useEffect(() => {
    dispatch({ type: reducerMethods.setNextList, payload: nextList });
  }, [nextList]);

  useEffect(() => {
    if (
      (isPdx && fields.zone?.length > 0) ||
      (isUsCan && fields.submodality?.length > 0)
    ) {
      setIsDisabled(false);
    } else setIsDisabled(!(isEmea && fields.submodality?.length > 0));
  }, [fields]);

  useEffect(() => {
    if (state.user && state.user.userEmail) {
      //When we have a valid user, check the defaultModel
      // if "all", then require they select a source
      if (!fields.source) {
        if (state.user.defaultModel === 'ALL') {
          fields.source = '';
        }
        if (state.user.defaultModel === 'PDx') {
          fields.source = 'PDx';
          setNextList(ListType.regions);
        }
        if (state.user.defaultModel === 'EMEA') {
          fields.source = 'EMEA';
          setNextList(ListType.zones);
        }
        if (state.user.defaultModel === 'USCAN') {
          fields.source = 'USCAN';
          setNextList(ListType.regions);
        }
      }
    }
  }, [state.user]);

  const resetFields = () => {
    //Clear fields of preselected values, if any
    //Note once we have user-pre values we may nee to adjust this.
    Object.keys(fields).forEach((key) => {
      if (key !== 'source') {
        fields[key] = '';
      }
    });

    //Clear state selections of filter list options
    dispatch({ type: reducerMethods.setAllTerritories, payload: [] });
    dispatch({ type: reducerMethods.setActiveTerritories, payload: [] });
    dispatch({ type: reducerMethods.setRegions, payload: [] });
    dispatch({ type: reducerMethods.setModalities, payload: [] });
    dispatch({ type: reducerMethods.setSubmodalities, payload: [] });
    dispatch({ type: reducerMethods.setZones, payload: [] });
    dispatch({ type: reducerMethods.setSelectedFacilities, payload: [] });
    dispatch({ type: reducerMethods.setFacilities, payload: [] });
    dispatch({ type: reducerMethods.setMarkets, payload: [] });
    dispatch({
      type: reducerMethods.setShouldShowTerritoryDropdown,
      payload: false,
    });
  };

  //Handle "admin" function emea/pdx source change
  useEffect(() => {
    if (isLoad.current) {
      isLoad.current = false;
      return;
    }

    if (fields.source) {
      resetFields();

      if (fields.source === 'PDx') {
        setPdx(true);
        setEmea(false);
        setIsUsCan(false);
        loadList(
          nextList && nextList !== ListType.none ? nextList : ListType.regions
        );
      } else if (fields.source === 'EMEA') {
        setPdx(false);
        setEmea(true);
        setIsUsCan(false);
        loadList(
          nextList && nextList !== ListType.none ? nextList : ListType.zones
        );
      } else if (fields.source === 'USCAN') {
        setPdx(false);
        setEmea(false);
        setIsUsCan(true);
        loadList(
          nextList && nextList !== ListType.none ? nextList : ListType.regions
        );
      }
    }
  }, [fields.source]);

  useEffect(() => {
    loadList(nextList);
  }, [listChange]);

  //Make sure we refilter the territories if for some reason (like emea/pdx source change) they change
  useEffect(() => {
    if (state.all_territories) {
      filterTerritories();
    }
  }, [state.all_territories]);

  // Clears facilities on tab switch for USCAN
  useEffect(() => {
    if (selectedTab !== state.selectedTab) {
      fields.territory = '';
      setTotalFacilities(0);
      setFacilitiesLoaded(0);
      setParentName('');
      dispatch({ type: reducerMethods.setFacilities, payload: [] });
      dispatch({ type: reducerMethods.setSelectedFacilities, payload: [] });
      dispatch({ type: reducerMethods.setTerritory, payload: undefined });
      dispatch({ type: reducerMethods.setParentName, payload: '' });
      dispatch({ type: reducerMethods.setSelectedTab, payload: selectedTab });
    }
  }, [selectedTab]);

  useEffect(() => {
    let e;

    if (fields.source === 'PDx') {
      if (state.regions?.length === 1 && fields.region === '') {
        e = { target: { value: state.regions[0].value, name: 'region' } };
        handleListChange(e as SelectChangeEvent, ListType.modalities);
      }

      if (state.modalities?.length === 1 && fields.modality === '') {
        e = {
          target: { value: state.modalities[0].value, name: 'submodality' },
        };
        handleListChange(e as SelectChangeEvent, ListType.submodalities);
      }

      if (state.submodalities?.length === 1 && fields.submodality === '') {
        e = {
          target: { value: state.submodalities[0].value, name: 'submodality' },
        };
        handleListChange(e as SelectChangeEvent, ListType.zones);
      }

      if (state.zones?.length === 1 && fields.zone === '') {
        e = { target: { value: state.zones[0].value, name: 'zone' } };
        handleListChange(e as SelectChangeEvent, ListType.none);
      }
    }

    if (fields.source === 'EMEA') {
      if (state.zones?.length === 1 && fields.zone === '') {
        e = { target: { value: state.zones[0].value, name: 'zone' } };
        handleListChange(e as SelectChangeEvent, ListType.regions);
      }

      if (state.regions?.length === 1 && fields.region === '') {
        e = { target: { value: state.regions[0].value, name: 'region' } };
        handleListChange(e as SelectChangeEvent, ListType.modalities);
      }

      if (state.modalities?.length === 1 && fields.modality === '') {
        e = { target: { value: state.modalities[0].value, name: 'modality' } };
        handleListChange(e as SelectChangeEvent, ListType.submodalities);
      }

      if (state.submodalities?.length === 1 && fields.submodality === '') {
        e = {
          target: { value: state.submodalities[0].value, name: 'submodality' },
        };
        handleListChange(e as SelectChangeEvent, ListType.none);
      }
    }

    if (fields.source === 'USCAN') {
      if (state.regions?.length === 1 && fields.region === '') {
        e = { target: { value: state.regions[0].value, name: 'region' } };
        handleListChange(e as SelectChangeEvent, ListType.markets);
      }

      if (state.markets?.length === 1 && fields.market === '') {
        e = { target: { value: state.markets[0].value, name: 'market' } };
        handleListChange(e as SelectChangeEvent, ListType.modalities);
      }

      if (state.modalities?.length === 1 && fields.modality === '') {
        e = { target: { value: state.modalities[0].value, name: 'modality' } };
        handleListChange(e as SelectChangeEvent, ListType.submodalities);
      }

      if (state.submodalities?.length === 1 && fields.submodality === '') {
        e = {
          target: { value: state.submodalities[0].value, name: 'submodality' },
        };
        handleListChange(e as SelectChangeEvent, ListType.none);
      }
    }
  }, [
    state.zones,
    state.regions,
    state.modalities,
    state.submodalities,
    state.markets,
  ]);

  const handleListChange = (
    e: SelectChangeEvent<string>,
    nextList: ListType
  ) => {
    if (e.target.name === 'source') {
      dispatch({ type: reducerMethods.setActiveCountry, payload: undefined });
    }
    if (fields.source === 'PDx') {
      dispatch({
        type: reducerMethods.setShouldShowTerritoryDropdown,
        payload: false,
      });
      switch (e.target.name) {
        case 'region':
          dispatch({ type: reducerMethods.setModalities, payload: undefined });
          dispatch({
            type: reducerMethods.setSubmodalities,
            payload: undefined,
          });
          dispatch({ type: reducerMethods.setZones, payload: undefined });
          break;
        case 'modality':
          dispatch({
            type: reducerMethods.setSubmodalities,
            payload: undefined,
          });
          dispatch({ type: reducerMethods.setZones, payload: undefined });
          break;
        case 'submodality':
          dispatch({ type: reducerMethods.setZones, payload: undefined });
          break;
        case 'zone':
          dispatch({ type: reducerMethods.setTerritory, payload: undefined });
          dispatch({
            type: reducerMethods.setShouldShowTerritoryDropdown,
            payload: true,
          });
          break;
        default:
          break;
      }
    } else if (fields.source === 'EMEA') {
      dispatch({
        type: reducerMethods.setShouldShowTerritoryDropdown,
        payload: false,
      });
      switch (e.target.name) {
        case 'zone':
          dispatch({ type: reducerMethods.setRegions, payload: undefined });
          dispatch({ type: reducerMethods.setModalities, payload: undefined });
          dispatch({
            type: reducerMethods.setSubmodalities,
            payload: undefined,
          });
          dispatch({ type: reducerMethods.setTerritory, payload: undefined });
          dispatch({
            type: reducerMethods.setActiveCountry,
            payload: undefined,
          });
          break;
        case 'region':
          dispatch({ type: reducerMethods.setModalities, payload: undefined });
          dispatch({
            type: reducerMethods.setSubmodalities,
            payload: undefined,
          });
          dispatch({ type: reducerMethods.setTerritory, payload: undefined });
          dispatch({
            type: reducerMethods.setActiveCountry,
            payload: e.target.value,
          });
          break;
        case 'modality':
          dispatch({
            type: reducerMethods.setSubmodalities,
            payload: undefined,
          });
          dispatch({ type: reducerMethods.setTerritory, payload: undefined });
          break;
        case 'submodality':
          dispatch({ type: reducerMethods.setTerritory, payload: undefined });
          dispatch({
            type: reducerMethods.setShouldShowTerritoryDropdown,
            payload: true,
          });
          break;
        default:
          break;
      }
    } else if (fields.source === 'USCAN') {
      dispatch({
        type: reducerMethods.setShouldShowTerritoryDropdown,
        payload: false,
      });
      switch (e.target.name) {
        case 'region':
          dispatch({ type: reducerMethods.setMarkets, payload: undefined });
          dispatch({ type: reducerMethods.setModalities, payload: undefined });
          dispatch({
            type: reducerMethods.setSubmodalities,
            payload: undefined,
          });
          dispatch({ type: reducerMethods.setTerritory, payload: undefined });
          dispatch({ type: reducerMethods.setParentName, payload: '' });
          break;
        case 'market':
          dispatch({ type: reducerMethods.setModalities, payload: undefined });
          dispatch({
            type: reducerMethods.setSubmodalities,
            payload: undefined,
          });
          dispatch({ type: reducerMethods.setTerritory, payload: undefined });
          dispatch({ type: reducerMethods.setParentName, payload: '' });
          break;
        case 'modality':
          dispatch({
            type: reducerMethods.setSubmodalities,
            payload: undefined,
          });
          dispatch({ type: reducerMethods.setTerritory, payload: undefined });
          dispatch({ type: reducerMethods.setParentName, payload: '' });
          break;
        case 'submodality':
          dispatch({ type: reducerMethods.setTerritory, payload: undefined });
          dispatch({ type: reducerMethods.setParentName, payload: '' });
          dispatch({
            type: reducerMethods.setShouldShowTerritoryDropdown,
            payload: true,
          });
          break;
        default:
          break;
      }
    }

    if (state.facilities && state.facilities.length > 0) {
      dispatch({ type: reducerMethods.setFacilities, payload: [] });
      dispatch({ type: reducerMethods.setSelectedFacilities, payload: [] });
      setIsAdditionalExpanded(false);
      setIsMapSettingsExpanded(false);
      setUnfilteredFacilities([]);
    }

    handleFieldChange(e);
    setNextList(nextList);
    setListChange(!listChange); //Toggle to keep reloading/filtering even if same list is just new selection (toggling on listType detection won't work)
    setTotalFacilities(0);
    setFacilitiesLoaded(0);
    setParentName('');
  };

  const handleTerritorySelect = (e: SelectChangeEvent<string>) => {
    setTotalFacilities(0);
    setFacilitiesLoaded(0);
    dispatch({ type: reducerMethods.addLoad });
    handleFieldChange(e);
    getFacilities(e.target.value);
  };

  const generateSelectOptions = (list: Array<OptionEntry>) => {
    return list
      .filter((option: OptionEntry) => {
        return option.value && option.label;
      })
      .map((option: OptionEntry, i) => {
        return (
          <MenuItem key={i} value={option.value}>
            {option.label}
          </MenuItem>
        );
      });
  };

  const getFacilities = (territory: string) => {
    dispatch({ type: reducerMethods.setTerritory, payload: territory });
    dispatch({ type: reducerMethods.setFacilities, payload: [] });
    api
      .getFacilitiesForTerritory(
        territory,
        state.mapFilterStatus.managerFilterSelections.source,
        setFacilitiesLoaded,
        setTotalFacilities,
        dispatch
      )
      .then((facilities) => {
        setIsAdditionalExpanded(true);
        setIsMapSettingsExpanded(true);
        dispatch({ type: reducerMethods.completeLoad });
        setUnfilteredFacilities(facilities);
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: reducerMethods.completeLoad });
      });
  };
  // All New For Parent Search
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleKeydown = (event: any) => {
    if (parentName.length > 2) {
      if (event.key === 'Enter') {
        setTotalFacilities(0);
        setFacilitiesLoaded(0);
        dispatch({ type: reducerMethods.addLoad });
        dispatch({ type: reducerMethods.setParentName, payload: parentName });
        getFacilitiesParentSearch();
      }
    }
  };

  const handleSearch = () => {
    if (parentName.length > 2) {
      setTotalFacilities(0);
      setFacilitiesLoaded(0);
      dispatch({ type: reducerMethods.addLoad });
      dispatch({ type: reducerMethods.setParentName, payload: parentName });
      getFacilitiesParentSearch();
    }
  };

  const getFacilitiesParentSearch = () => {
    dispatch({ type: reducerMethods.setFacilities, payload: [] });
    api
      .getFacilitiesParentSearch(
        parentName,
        fields.submodality,
        setFacilitiesLoaded,
        setTotalFacilities,
        dispatch
      )
      .then((facilities) => {
        setIsAdditionalExpanded(true);
        setIsMapSettingsExpanded(true);
        dispatch({ type: reducerMethods.completeLoad });
        setUnfilteredFacilities(facilities);
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: reducerMethods.completeLoad });
      });
  };

  const loadTerritoriesForUser = (
    region: string | null,
    modality?: string | null,
    submodality?: string | null
  ) => {
    if (region && fields.source === 'PDx') {
      // This dispatch method hasn't been updated because 'SET_REGION' wasn't ever implemented.
      // What is this supposed to do?
      dispatch({ type: 'SET_REGION', payload: region });
    }

    dispatch({ type: reducerMethods.addLoad });

    if (!fields.source) {
      throw new Error('Cannot call load territories without a source');
    }
    api
      .getTerritoriesForUser(
        state.user?.userEmail ?? '',
        fields.source,
        region || '',
        modality || '',
        submodality || ''
      )
      .then((territories) => {
        dispatch({
          type: reducerMethods.setAllTerritories,
          payload: territories,
        });
        dispatch({
          type: reducerMethods.setActiveTerritories,
          payload: territories,
        });
        dispatch({ type: reducerMethods.completeLoad });
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: reducerMethods.completeLoad });
      }); //TODO: Real error handling
  };

  const filterTerritories = () => {
    if (state.all_territories && state.all_territories.length > 0) {
      let activeTerritories = [...state.all_territories];

      if (fields.region) {
        activeTerritories = activeTerritories.filter(
          (terr: Territory) => terr.accountRegionCode === fields.region
        );
      }
      if (fields.modality) {
        activeTerritories = activeTerritories.filter(
          (terr: Territory) => terr.accountModCode === fields.modality
        );
      }
      if (fields.submodality) {
        activeTerritories = activeTerritories.filter(
          (terr: Territory) => terr.accountSubModCode === fields.submodality
        );
      }
      if (fields.zone) {
        activeTerritories = activeTerritories.filter(
          (terr: Territory) => terr.accountZoneCode === fields.zone
        );
      }

      dispatch({
        type: reducerMethods.setActiveTerritories,
        payload: activeTerritories,
      });
    }
  };

  const loadList = (listType: ListType) => {
    dispatch({ type: reducerMethods.addLoad });

    if (isPdx) {
      //Clear prior settings for next list type
      switch (listType) {
        case ListType.regions:
          fields.region = '';
          fields.modality = '';
          fields.submodality = '';
          fields.zone = '';
          fields.territory = '';
          break;
        case ListType.modalities:
          fields.modality = '';
          fields.submodality = '';
          fields.zone = '';
          fields.territory = '';
          break;
        case ListType.submodalities:
          fields.submodality = '';
          fields.zone = '';
          fields.territory = '';
          break;
        case ListType.zones:
          loadTerritoriesForUser(
            fields.region,
            fields.modality,
            fields.submodality
          );
          fields.territory = '';
      }
    }

    if (isEmea) {
      //Clear prior settings for next list type
      switch (listType) {
        case ListType.zones:
          fields.zone = '';
          fields.region = '';
          fields.modality = '';
          fields.submodality = '';
          fields.territory = '';
          break;
        case ListType.regions:
          fields.region = '';
          fields.modality = '';
          fields.submodality = '';
          fields.territory = '';
          break;
        case ListType.modalities:
          fields.modality = '';
          fields.submodality = '';
          fields.territory = '';
          break;
        case ListType.submodalities:
          fields.submodality = '';
          fields.territory = '';
          loadTerritoriesForUser(
            fields.region,
            fields.modality,
            fields.submodality
          );
          break;
      }
    }

    if (isUsCan) {
      //Clear prior settings for next list type
      switch (listType) {
        case ListType.regions:
          fields.region = '';
          fields.market = '';
          fields.modality = '';
          fields.submodality = '';
          fields.territory = '';
          break;
        case ListType.markets:
          fields.market = '';
          fields.modality = '';
          fields.submodality = '';
          fields.territory = '';
          break;
        case ListType.modalities:
          fields.modality = '';
          fields.submodality = '';
          fields.territory = '';
          break;
        case ListType.submodalities:
          fields.submodality = '';
          fields.territory = '';
          loadTerritoriesForUser(
            fields.region,
            fields.modality,
            fields.submodality
          );
          break;
      }
    }

    if (listType !== ListType.none) {
      api
        .getList(
          listType,
          fields.source,
          fields.region,
          fields.modality,
          fields.submodality,
          isEmea ? fields.zone : '',
          isUsCan ? fields.market : ''
        )
        .then((listData) => {
          switch (listType) {
            case ListType.sources:
              dispatch({ type: reducerMethods.setSources, payload: listData });
              break;
            case ListType.regions:
              dispatch({ type: reducerMethods.setRegions, payload: listData });
              break;
            case ListType.modalities:
              dispatch({
                type: reducerMethods.setModalities,
                payload: listData,
              });
              break;
            case ListType.submodalities:
              dispatch({
                type: reducerMethods.setSubmodalities,
                payload: listData,
              });
              break;
            case ListType.zones:
              dispatch({ type: reducerMethods.setZones, payload: listData });
              break;
            case ListType.markets:
              dispatch({ type: reducerMethods.setMarkets, payload: listData });
              break;
            default:
              console.log(`Unrecognized list type ${listType}`);
          }
        })
        .catch((e) => {
          console.log(`Problem loading list ${listType}`);
          dispatch({ type: reducerMethods.completeLoad });
          throw e;
        })
        .finally(() => {
          finish();
        });
    } else {
      finish();
    }

    function finish() {
      filterTerritories();
      // setNextList(ListType.none);
      dispatch({ type: reducerMethods.completeLoad });
    }
  };

  function renderSpinner() {
    return <Loading />;
  }

  function getFacilityLoadedMessage() {
    if (facilitiesLoaded === totalFacilities) {
      return (
        <span>
          Loaded {facilitiesLoaded} of {totalFacilities}
        </span>
      );
    } else {
      return (
        <span>
          Loading {facilitiesLoaded} of {totalFacilities}
        </span>
      );
    }
  }

  function renderPDxFilterForm() {
    return (
      <>
        {state.regions && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Region</div>
              <Select
                name="region"
                inputProps={{ 'aria-label': 'Without label' }}
                value={
                  state.regions.length === 1
                    ? state.regions[0].value
                    : fields.region
                }
                onChange={(e) => handleListChange(e, ListType.modalities)}
                disabled={state.regions.length === 0 || !!state.isLoading}
                sx={{ height: '40px' }}
                renderValue={(value: string) => {
                  const item = state.regions!.find(
                    (i: SelectOption) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.regions)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
        {state.modalities && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Modality</div>
              <Select
                name="modality"
                inputProps={{ 'aria-label': 'Without label' }}
                value={
                  state.modalities.length === 1
                    ? state.modalities[0].value
                    : fields.modality
                }
                onChange={(e) => handleListChange(e, ListType.submodalities)}
                disabled={state.modalities.length === 0 || !!state.isLoading}
                sx={{ height: '40px' }}
                renderValue={(value) => {
                  const item = state.modalities!.find(
                    (i: SelectOption) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.modalities)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
        {state.submodalities && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Sub Modality</div>
              <Select
                name="submodality"
                inputProps={{ 'aria-label': 'Without label' }}
                value={
                  state.submodalities.length === 1
                    ? state.submodalities[0].value
                    : fields.submodality
                }
                onChange={(e) => handleListChange(e, ListType.zones)}
                disabled={state.submodalities.length === 0 || !!state.isLoading}
                sx={{ height: '40px' }}
                renderValue={(value) => {
                  const item = state.submodalities!.find(
                    (i: SelectOption) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.submodalities)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
        {state.zones && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Zone</div>
              <Select
                name="zone"
                inputProps={{ 'aria-label': 'Without label' }}
                value={
                  state.zones.length === 1 ? state.zones[0].value : fields.zone
                }
                onChange={(e) => handleListChange(e, ListType.none)}
                disabled={state.zones.length === 0 || !!state.isLoading}
                sx={{ height: '40px' }}
                renderValue={(value) => {
                  const item = state.zones!.find(
                    (i: SelectOption) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.zones)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
      </>
    );
  }

  function renderEmeaFilterForm() {
    return (
      <>
        {state.zones && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Zone</div>
              <Select
                name="zone"
                inputProps={{ 'aria-label': 'Without label' }}
                value={
                  state.zones.length === 1 ? state.zones[0].value : fields.zone
                }
                onChange={(e) => handleListChange(e, ListType.regions)}
                disabled={state.zones.length === 0 || !!state.isLoading}
                sx={{ height: '40px' }}
                renderValue={(value) => {
                  const item = state.zones!.find(
                    (i: SelectOption) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.zones)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
        {state.regions && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Country</div>
              <Select
                name="region"
                inputProps={{ 'aria-label': 'Without label' }}
                value={
                  state.regions.length === 1
                    ? state.regions[0].value
                    : fields.region
                }
                onChange={(e) => handleListChange(e, ListType.modalities)}
                disabled={state.regions.length === 0 || !!state.isLoading}
                sx={{ height: '40px' }}
                renderValue={(value) => {
                  const item = state.regions!.find(
                    (i: SelectOption) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.regions)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
        {state.modalities && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Modality</div>
              <Select
                name="modality"
                inputProps={{ 'aria-label': 'Without label' }}
                value={
                  state.modalities.length === 1
                    ? state.modalities[0].value
                    : fields.modality
                }
                onChange={(e) => handleListChange(e, ListType.submodalities)}
                disabled={state.modalities.length === 0 || !!state.isLoading}
                sx={{ height: '40px' }}
                renderValue={(value) => {
                  const item = state.modalities!.find(
                    (i: { value: string }) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.modalities)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
        {state.submodalities && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Sub Modality</div>
              <Select
                name="submodality"
                inputProps={{ 'aria-label': 'Without label' }}
                value={
                  state.submodalities.length === 1
                    ? state.submodalities[0].value
                    : fields.submodality
                }
                onChange={(e) => handleListChange(e, ListType.none)}
                disabled={state.submodalities.length === 0 || !!state.isLoading}
                sx={{ height: '40px' }}
                renderValue={(value) => {
                  const item = state.submodalities!.find(
                    (i: { value: string }) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.submodalities)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
        {state.shouldShowTerritoryDropdown && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Territory</div>
              <Select
                name="territory"
                inputProps={{ 'aria-label': 'Without label' }}
                value={fields.territory}
                onChange={(e) => handleTerritorySelect(e)}
                disabled={
                  state.active_territories.length === 0 ||
                  (isEmea && fields.submodality.length === 0) ||
                  (isPdx && fields.zone.length === 0) ||
                  !!state.isLoading
                }
                sx={{ height: '40px' }}
                renderValue={(value) => {
                  const item = territoryHelper(state.active_territories).find(
                    (i: { value: string }) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(
                  territoryHelper(state.active_territories)
                )}
              </Select>
            </FormControl>
            <br />
            {(facilitiesLoaded > 0 || totalFacilities > 0) &&
              getFacilityLoadedMessage()}
            <br />
            <br />
          </div>
        )}
      </>
    );
  }

  function renderUSCanFilterForm() {
    return (
      <>
        {/*Default US for country*/}
        <div>
          <FormControl fullWidth={true}>
            <div style={{ marginBottom: '.5rem' }}>Country</div>
            <Select
              name="country"
              inputProps={{ 'aria-label': 'Without label' }}
              value={'US'}
              disabled={!!state.isLoading}
              sx={{ height: '40px' }}
              renderValue={(value: string) => {
                return (
                  <div style={{ textAlign: 'left', alignItems: 'center' }}>
                    {value}
                  </div>
                );
              }}
            >
              <MenuItem key={'US'} value={'US'}>
                US
              </MenuItem>
            </Select>
          </FormControl>
          <br />
          <br />
        </div>
        {state.regions && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Region</div>
              <Select
                name="region"
                inputProps={{ 'aria-label': 'Without label' }}
                value={
                  state.regions.length === 1
                    ? state.regions[0].value
                    : fields.region
                }
                onChange={(e) => handleListChange(e, ListType.markets)}
                disabled={state.regions.length === 0 || !!state.isLoading}
                sx={{ height: '40px' }}
                renderValue={(value: string) => {
                  const item = state.regions!.find(
                    (i: SelectOption) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.regions)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
        {state.markets && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Market</div>
              <Select
                name="market"
                inputProps={{ 'aria-label': 'Without label' }}
                value={
                  state.markets.length === 1
                    ? state.markets[0].value
                    : fields.market
                }
                onChange={(e) => handleListChange(e, ListType.modalities)}
                disabled={state.markets.length === 0 || !!state.isLoading}
                sx={{ height: '40px' }}
                renderValue={(value) => {
                  const item = state.markets!.find(
                    (i: SelectOption) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.markets)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
        {state.modalities && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Modality</div>
              <Select
                name="modality"
                inputProps={{ 'aria-label': 'Without label' }}
                value={
                  state.modalities.length === 1
                    ? state.modalities[0].value
                    : fields.modality
                }
                onChange={(e) => handleListChange(e, ListType.submodalities)}
                disabled={state.modalities.length === 0 || !!state.isLoading}
                sx={{ height: '40px' }}
                renderValue={(value) => {
                  const item = state.modalities!.find(
                    (i: SelectOption) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.modalities)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
        {state.submodalities && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Sub Modality</div>
              <Select
                name="submodality"
                inputProps={{ 'aria-label': 'Without label' }}
                value={
                  state.submodalities.length === 1
                    ? state.submodalities[0].value
                    : fields.submodality
                }
                onChange={(e) => handleListChange(e, ListType.none)}
                disabled={state.submodalities.length === 0 || !!state.isLoading}
                sx={{ height: '40px' }}
                renderValue={(value) => {
                  const item = state.submodalities!.find(
                    (i: SelectOption) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.submodalities)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
        {state.shouldShowTerritoryDropdown && (
          <>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              centered
              sx={{ marginBottom: '1rem' }}
            >
              <Tab
                label="Territory Search"
                value={USCANPanelTabs.TerritorySearch}
              />
              <Tab label="Parent Search" value={USCANPanelTabs.ParentSearch} />
            </Tabs>

            {selectedTab === USCANPanelTabs.TerritorySearch && (
              <div>
                <FormControl fullWidth={true}>
                  <SelectLabel>Territory</SelectLabel>
                  <Select
                    name="territory"
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={fields.territory}
                    onChange={(e) => handleTerritorySelect(e)}
                    disabled={
                      state.active_territories.length === 0 ||
                      (isEmea && fields.submodality.length === 0) ||
                      (isPdx && fields.zone.length === 0) ||
                      !!state.isLoading
                    }
                    sx={{ height: '40px' }}
                    renderValue={(value) => {
                      const item = territoryHelper(
                        state.active_territories
                      ).find((i: { value: string }) => i.value === value);
                      return (
                        <div
                          style={{ textAlign: 'left', alignItems: 'center' }}
                        >
                          {item?.label ?? ''}
                        </div>
                      );
                    }}
                  >
                    {generateSelectOptions(
                      territoryHelper(state.active_territories)
                    )}
                  </Select>
                </FormControl>
                <br />
                {(facilitiesLoaded > 0 || totalFacilities > 0) &&
                  getFacilityLoadedMessage()}
              </div>
            )}

            {selectedTab === USCANPanelTabs.ParentSearch && (
              <div>
                <FormControl fullWidth={true}>
                  <SelectLabel>Parent</SelectLabel>
                  <TextField
                    fullWidth
                    id="standard-bare"
                    variant="outlined"
                    value={parentName}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleSearch}>
                          <SearchOutlinedIcon />
                        </IconButton>
                      ),
                    }}
                    onChange={(e) => setParentName(e.target.value)}
                    onKeyDown={handleKeydown}
                  />
                </FormControl>
                <br />
                {(facilitiesLoaded > 0 || totalFacilities > 0) &&
                  getFacilityLoadedMessage()}
              </div>
            )}
          </>
        )}
      </>
    );
  }

  const territoryHelper = (territoryList: any) => {
    return territoryList.map(
      (territory: {
        accountFinalTerritory: string;
        accountFinalTerritoryCode: string;
      }) => {
        return {
          value: territory.accountFinalTerritoryCode,
          label: territory.accountFinalTerritory,
        } as OptionEntry;
      }
    );
  };

  return (
    <Accordion defaultExpanded disabled={!!state.isLoading}>
      {!!state.isLoading && renderSpinner()}
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Sales Selector</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {state.user?.defaultModel === 'ALL' && state.sources && (
          <div>
            <FormControl fullWidth={true}>
              <div style={{ marginBottom: '.5rem' }}>Source</div>
              <Select
                name="source"
                value={fields.source}
                onChange={(e) => handleListChange(e, ListType.none)}
                disabled={state.sources.length === 0 || !!state.isLoading}
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ height: '40px' }}
                renderValue={(value) => {
                  const item = state.sources.find(
                    (i: { value: string }) => i.value === value
                  );
                  return (
                    <div style={{ textAlign: 'left', alignItems: 'center' }}>
                      {item?.label ?? ''}
                    </div>
                  );
                }}
              >
                {generateSelectOptions(state.sources)}
              </Select>
            </FormControl>
            <br />
            <br />
          </div>
        )}
        {isPdx && !isEmea && !isUsCan && renderPDxFilterForm()}
        {!isPdx && isEmea && !isUsCan && renderEmeaFilterForm()}
        {!isPdx && !isEmea && isUsCan && renderUSCanFilterForm()}
      </AccordionDetails>
    </Accordion>
  );
}
