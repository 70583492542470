import styled from '@emotion/styled';
import tinycolor from 'tinycolor2';

export const mapBoxComponent = styled.div`
  position: relative;

  .sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 12px;
    border-radius: 4px;
  }
`;

export const marker_btn = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  margin-left: -5px;

  img {
    width: 20px;
    height: 20px;
    margin: 0 0 0 -20px;
  }
`;

export const TerritoryLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px black solid;
  background: white;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
  gap: 4px;
  position: relative;
  left: -50%;
`;

export const LabelText = styled.text`
  margin: 0 auto;
  font-size: 12px;
`;

type FillArgs = {
  color: string;
};

export const ColorBox = styled.div<FillArgs>`
  width: 10px;
  height: 10px;
  border: 1px ${(props) => props.color} solid;
  background: ${(props) => tinycolor(props.color).setAlpha(0.5).toHex8String()};
  border-radius: 2px;
`;

export const PositionInfoContainer = styled.div`
  position: relative;
  width: 100%;

  & > div {
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);

    div {
      flex: 1;
    }
  }
`;

export const FacilityPopup = styled.div`
  text-align: left;
  margin-top: -4px;
`;

export const circle = styled.div`
  width: 6px;
  height: 10px;
  background-color: blue;
`;

export const Container = styled.div`
  position: relative;
`;

export const ScenarioChangeText = styled.text`
  color: red;
`;
