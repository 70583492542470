import React, { createContext, FC, useReducer } from 'react';
import AppReducer from './app.reducer';
import { FilterContext } from './components/shared/types';
import { ListType } from './lib/api';

const initialState: FilterContext = {
  allowRegionSelect: false,
  active_scenario: 0,
  active_territories: [],
  sources: [],
  user: undefined,
  modalities: [],
  modality: undefined,
  submodalities: [],
  markets: [],
  market: undefined,
  zones: [],
  zone: undefined,
  facilities: [],
  facility: undefined,
  error: undefined,
  facilityPinColorSettings: 0,
  isManagerOpen: true,
  mapFilterStatus: {
    managerFilterSelections: {
      source: '',
      region: '',
      modality: '',
      submodality: '',
      zone: '',
      territory: '',
      market: '',
    },
    nextList: ListType.sources,
  },
  additionalFilters: undefined,
  isLoading: 0,
  shouldShowTerritoryDropdown: false,
  showTerritoryLabels: true,
  selectedTab: 0,
  parentName: '',
};

export const AppContext = createContext([initialState, undefined] as [
  FilterContext,
  any
]);

const AppStore: FC<unknown> = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  return (
    <AppContext.Provider value={[state, dispatch]}>
      {children}
    </AppContext.Provider>
  );
};

export default AppStore;
