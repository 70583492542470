import { Facility, Territory } from '@voiant/dataconnector';
import { Dispatch } from 'react';
import { ListType } from '../../lib/api';

export type SelectOption = {
  label: string;
  value: string;
  id?: string;
};

export type FacilityData = Facility;

export type UserData = {
  accessLevel: string;
  assignedCode: string;
  attributes: {
    email: string;
    email_verified: boolean;
    phone_number: string;
    phone_number_verified: true;
    sub: string;
  };
  defaultFilter: string;
  defaultLat: number;
  defaultLong: number;
  defaultModel: 'ALL' | 'PDx' | 'EMEA' | 'USCAN';
  id?: string;
  role: 'READWRITE' | 'READONLY' | 'NOACCESS';
  userEmail: string;
  username: string;
  //This is being used, should it be here?
  password: string;
  zoom?: number;
};

export type FieldsDict = {
  [key: string]: string;
};

export type FullContext = [state: FilterContext, dispatch: Dispatch<any>];

export type TerritoryManagerFields = {
  source: string;
  region: string;
  modality: string;
  submodality: string;
  zone: string;
  territory: string;
  market: string;
};

export type AdditionalFiltersFields = {
  textFilter?: string;
  accountScoreRange?: number[];
  ibCountRange?: number[];
  currentFunnelRange?: number[];
  pastSalesRange?: number[];
  totalIBDollarValueRange?: number[];
  facilityTypeFilter?: string;
  accountPrioritizationFilter?: string[];
};

export type PostalRegion = {
  postalCode: string;
  postalCodeCode: string;
  territory: string;
  facilities: FacilityData[];
  featureId: number;
};

export type FilterContext = {
  all_territories?: any;
  active_territories: Territory[];
  active_country?: string;
  active_scenario: number;
  facility?: FacilityData;
  facilities: FacilityData[];
  market?: string;
  markets?: SelectOption[];
  modality?: SelectOption;
  modalities?: SelectOption[];
  regions?: SelectOption[];
  selected_facilities?: FacilityData[];
  selected_postal_regions?: PostalRegion[];
  sources: SelectOption[];
  submodalities?: SelectOption[];
  territory?: string;
  user?: UserData;
  zone?: SelectOption;
  zones?: SelectOption[];
  facilityPinColorSettings: number;
  allowRegionSelect: boolean;
  isManagerOpen: boolean;
  mapFilterStatus: {
    managerFilterSelections: TerritoryManagerFields;
    nextList: ListType;
  };
  additionalFilters?: AdditionalFiltersFields;
  isLoading: number;
  shouldShowTerritoryDropdown: boolean;
  showTerritoryLabels: boolean;
  selectedTab: number;
  parentName: string;
  error?: string;
};

export enum ColorSettings {
  territory,
  facilityType,
  accountScore,
}
