import styled from '@emotion/styled';

export const Header = styled.div`
  text-align: center;
  color: darkgray;
`;

export const Footer = styled.div`
  text-align: right;
`;

export const GeLogo = styled.img`
  height: 80px;
  width: auto;
`;

export const VoiantLogo = styled.img`
  width: 50px;
  height: auto;
  padding: 10px 0 0 1px;
`;
