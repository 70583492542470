import { useContext, useEffect, useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import {
  AmplifyAuthenticator,
  AmplifyConfirmSignUp,
  AmplifyForgotPassword,
  AmplifyRequireNewPassword,
  AmplifySignIn,
  AmplifySignUp,
  AmplifyVerifyContact,
} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import AppStore, { AppContext } from './app.store';
import { Layout } from './pages/layout/layout';
import './app.module.scss';
import { Footer, GeLogo, Header, VoiantLogo } from './styles';
import { FullContext } from './components/shared/types';
import appConfig from '../assets/config.json';

const config = {
  mandatorySignIn: true,
  region: appConfig?.cognito.region,
  userPoolId: appConfig?.cognito.userPoolId,
  userPoolWebClientId: appConfig?.cognito.userPoolWebClientId,
};

Amplify.configure(config);

Auth.configure(config);

export function App() {
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<any>();
  const [state, dispatch] = useContext<FullContext>(AppContext);

  useEffect(() => {
    if (authState === undefined) {
      Auth.currentAuthenticatedUser().then((authData) => {
        setAuthState(AuthState.SignedIn);
        setUser(authData);
      });
    }

    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  const header = (
    <Header>
      <GeLogo src="/assets/GE_Healthcare.png" />
      <h2>Sales Territory Visualization</h2>
    </Header>
  );
  const footer = (
    <Footer>
      <span>
        Powered by
        <VoiantLogo src="/assets/Voiant55h.png" />
      </span>
    </Footer>
  );

  return (
    <AppStore>
      {authState === AuthState.SignedIn && user ? (
        <Layout />
      ) : (
        <AmplifyAuthenticator usernameAlias="email">
          <div slot="sign-in">
            {header}
            <AmplifySignIn usernameAlias="email" />
            {footer}
          </div>
          <div slot="sign-up">
            {header}
            <AmplifySignUp
              usernameAlias="email"
              formFields={[{ type: 'email' }, { type: 'password' }]}
            />
            {footer}
          </div>
          <div slot="verify-contact">
            {header}
            <AmplifyVerifyContact />
            {footer}
          </div>
          <div slot="confirm-sign-up">
            {header}
            <AmplifyConfirmSignUp usernameAlias="email" />
            {footer}
          </div>
          <div slot="forgot-password">
            {header}
            <AmplifyForgotPassword usernameAlias="email" />
            {footer}
          </div>
          <div slot="require-new-password">
            {header}
            <AmplifyRequireNewPassword />
            {footer}
          </div>
        </AmplifyAuthenticator>
      )}
    </AppStore>
  );
}

export default App;
