import React, { useContext } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FullContext } from '../../shared/types';
import { AppContext } from '../../../app.store';
import styled from '@emotion/styled';

const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const HeaderEntry = styled.td`
  flex: 1;
`;

type ValueEntryProps = {
  fontSize?: string;
};

const ValueEntry = styled.div<ValueEntryProps>`
  flex: 1;
  font-size: ${(props) => props.fontSize ?? '18px'};
  font-weight: 400;
`;

const largeFont = '26px';

const AccountsTotalPanel = ({ showScenarios }: { showScenarios: boolean }) => {
  const [state, dispatch] = useContext<FullContext>(AppContext);

  const selectedSource = state.mapFilterStatus.managerFilterSelections.source;
  const accountScoreLabel = selectedSource === 'USCAN' ?
    'Account Index' : 'Account Score';

  const getSumFromObjectArray = (
    objectArray: Array<any>,
    property: string
  ): string => {
    let sum = 0;
    objectArray.forEach((obj) => (sum += Number(obj[property])));
    return sum.toFixed(2).toString();
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const activeFacilities = () => {
    if (state.selected_facilities && state.selected_facilities.length > 0) {
      return state.selected_facilities;
    }
    return state.facilities;
  };

  const currentFacilities = state.selectedTab === 1 ? activeFacilities() :
    activeFacilities()?.filter(
      (facility) => facility.accountFinalTerritoryCode === state.territory
    ) ?? [];
  const scenario1Facilities =
    activeFacilities()?.filter(
      (facility) => facility.scenarioOneCode === state.territory
    ) ?? [];
  const scenario2Facilities =
    activeFacilities()?.filter(
      (facility) => facility.scenarioTwoCode === state.territory
    ) ?? [];

  return (
    <Accordion expanded={(activeFacilities()?.length ?? 0) > 0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Filtered Accounts Totals</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {showScenarios && (
          <SummaryRow>
            <HeaderEntry>Current</HeaderEntry>
            <HeaderEntry>Scenario 1</HeaderEntry>
            <HeaderEntry>Scenario 2</HeaderEntry>
          </SummaryRow>
        )}
        <InputLabel>Count</InputLabel>
        <SummaryRow>
          <ValueEntry>
            {showScenarios ? currentFacilities.length : ''}
          </ValueEntry>
          <ValueEntry fontSize={!showScenarios ? largeFont : undefined}>
            {showScenarios
              ? scenario1Facilities.length
              : currentFacilities.length}
          </ValueEntry>
          <ValueEntry>
            {showScenarios ? scenario2Facilities.length : ''}
          </ValueEntry>
        </SummaryRow>
        <InputLabel id="zip-label">{accountScoreLabel}</InputLabel>
        <SummaryRow>
          <ValueEntry>
            {showScenarios
              ? getSumFromObjectArray(currentFacilities, 'accountScore') ?? 0
              : ''}
          </ValueEntry>
          <ValueEntry fontSize={!showScenarios ? largeFont : undefined}>
            {showScenarios
              ? getSumFromObjectArray(scenario1Facilities, 'accountScore') ?? 0
              : getSumFromObjectArray(currentFacilities, 'accountScore') ?? 0}
          </ValueEntry>
          <ValueEntry>
            {showScenarios
              ? getSumFromObjectArray(scenario2Facilities, 'accountScore') ?? 0
              : ''}
          </ValueEntry>
        </SummaryRow>

        {selectedSource === 'EMEA' && (
          <>
            <InputLabel id="zip-label">Current Funnel</InputLabel>
            <SummaryRow>
              <ValueEntry>
                {showScenarios
                  ? formatter.format(
                    Number(
                      getSumFromObjectArray(currentFacilities, 'currentFunnel')
                    )
                  )
                  : ''}
              </ValueEntry>
              <ValueEntry fontSize={!showScenarios ? largeFont : undefined}>
                {showScenarios
                  ? formatter.format(
                    Number(
                      getSumFromObjectArray(scenario1Facilities, 'currentFunnel')
                    )
                  )
                  : formatter.format(
                    Number(
                      getSumFromObjectArray(currentFacilities, 'currentFunnel')
                    )
                  )}
              </ValueEntry>
              <ValueEntry>
                {showScenarios
                  ? formatter.format(
                    Number(
                      getSumFromObjectArray(scenario2Facilities, 'currentFunnel')
                    )
                  )
                  : ''}
              </ValueEntry>
            </SummaryRow>
            <InputLabel id="zip-label">IB Count</InputLabel>
            <SummaryRow>
              <ValueEntry>
                {showScenarios
                  ? getSumFromObjectArray(currentFacilities, 'IB_Count')
                  : ''}
              </ValueEntry>
              <ValueEntry fontSize={!showScenarios ? largeFont : undefined}>
                {showScenarios
                  ? getSumFromObjectArray(scenario1Facilities, 'IB_Count')
                  : getSumFromObjectArray(currentFacilities, 'IB_Count')}
              </ValueEntry>
              <ValueEntry>
                {showScenarios
                  ? getSumFromObjectArray(scenario2Facilities, 'IB_Count')
                  : ''}
              </ValueEntry>
            </SummaryRow>
            <InputLabel id="zip-label">IB Value</InputLabel>
            <SummaryRow>
              <ValueEntry>
                {showScenarios
                  ? getSumFromObjectArray(currentFacilities, 'IB_Value')
                  : ''}
              </ValueEntry>
              <ValueEntry fontSize={!showScenarios ? largeFont : undefined}>
                {showScenarios
                  ? getSumFromObjectArray(scenario1Facilities, 'IB_Value')
                  : getSumFromObjectArray(currentFacilities, 'IB_Value')}
              </ValueEntry>
              <ValueEntry>
                {showScenarios
                  ? getSumFromObjectArray(scenario2Facilities, 'IB_Value')
                  : ''}
              </ValueEntry>
            </SummaryRow>
          </>
        )}

      </AccordionDetails>
    </Accordion>
  );
};

export default AccountsTotalPanel;
