import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  MenuItem,
  Select,
  Slider,
  Typography,
  ListItemIcon,
  ListItemText,
  Checkbox
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionSection, SelectLabel } from "../../styles";
import { reducerMethods } from "../../../../app.reducer";
import { FullContext } from "../../../shared/types";
import { AppContext } from "../../../../app.store";
import { Facility } from "@voiant/dataconnector";
import { facilityTypeColors } from "../../../../../../../../libs/dataconnector/src/lib/facilityTypeColors";
import { accountPrioritizationOptions } from "../../../../../../../../libs/dataconnector/src/lib/accountPrioritizationOptions";


export const AccountFilters = ({
 isDisabled,
 isAdditionalExpanded,
 setIsAdditionalExpanded,
 unfilteredFacilities
}: {
  isDisabled: boolean;
  isAdditionalExpanded: boolean;
  setIsAdditionalExpanded: (isAdditionalExpanded: boolean) => void;
  unfilteredFacilities: Facility[];
}) => {
  const [state, dispatch] = useContext<FullContext>(AppContext);

  const [textFilter, setTextFilter] = useState<string>(
    state.additionalFilters?.textFilter ?? ''
  );
  const [accountScoreRange, setAccountScoreRange] = useState<number[]>([0, 0]);
  const [facilityTypeFilter, setFacilityTypeFilter] = useState<string>(
    state.additionalFilters?.facilityTypeFilter ?? ''
  );
  const [accountPrioritizationFilter, setAccountPrioritizationFilter] = useState<string[]>(
    state.additionalFilters?.accountPrioritizationFilter ?? []
  );
  const accountScoreMax = useMemo(
    () =>
      Math.max(
        ...unfilteredFacilities.map(
          (facility: Facility) => facility['accountScore']
        )
      ),
    [unfilteredFacilities]
  );
  const isAllSelected =
    accountPrioritizationOptions.length > 0 && accountPrioritizationFilter.length === accountPrioritizationOptions.length;

  //Updates to filter the facilities
  useEffect(() => {
    dispatch({
      type: reducerMethods.setAdditionalFilterText,
      payload: textFilter,
    });
    dispatch({
      type: reducerMethods.setAdditionalFilterAccountScore,
      payload: accountScoreRange,
    });
    dispatch({
      type: reducerMethods.setAdditionalFilterFacilityType,
      payload: facilityTypeFilter,
    });
    dispatch({
      type: reducerMethods.setAdditionalFilterAccountPrioritization,
      payload: accountPrioritizationFilter,
    })

    let facilitiesBeingFiltered = unfilteredFacilities;

    if (textFilter.length > 2) {
      const lowerCasedFilter = textFilter.toLowerCase();

      facilitiesBeingFiltered = facilitiesBeingFiltered.filter((facility) => {
        return (
          facility.accountName?.toLowerCase().includes(lowerCasedFilter) ||
          facility.parentName?.toLowerCase().includes(lowerCasedFilter) ||
          facility.accountPrimaryPostal
            ?.toLowerCase()
            .includes(lowerCasedFilter) ||
          facility.ultimateParentAccountCode
            ?.toLowerCase()
            .includes(lowerCasedFilter) ||
          facility.accountUCMID?.toLowerCase().includes(lowerCasedFilter)
        );
      });
    }

    facilitiesBeingFiltered = facilitiesBeingFiltered.filter(
      (facility: { accountScore: number }) => {
        return getValuesInRange(facility.accountScore, accountScoreRange);
      }
    );

    if (facilityTypeFilter.length > 0) {
      facilitiesBeingFiltered = facilitiesBeingFiltered.filter(
        (facility: { facilityType: string }) => {
          return facility.facilityType === facilityTypeFilter;
        }
      );
    }

    if (accountPrioritizationFilter.length > 0) {
      facilitiesBeingFiltered = facilitiesBeingFiltered.filter(
        (facility: { accountPrioritization: string }) => {
          return accountPrioritizationFilter.includes(facility.accountPrioritization)
        }
      )
    }

    if (facilitiesBeingFiltered.length > 0) {
      dispatch({
        type: reducerMethods.setFacilities,
        payload: facilitiesBeingFiltered,
      });
    } else {
      dispatch({ type: reducerMethods.setFacilities, payload: [] });
    }
  }, [
    textFilter,
    accountScoreRange,
    facilityTypeFilter,
    accountPrioritizationFilter
  ]);

  useEffect(() => {
    setAccountScoreRange(
      state.additionalFilters?.accountScoreRange ?? [0, accountScoreMax]
    );
  }, [unfilteredFacilities]);

  function resetAdditionalFilters() {
    setTextFilter('');
    setAccountScoreRange([0, accountScoreMax]);
    setFacilityTypeFilter('');
    setAccountPrioritizationFilter([]);
    dispatch({
      type: reducerMethods.setFacilities,
      payload: unfilteredFacilities,
    });
  };

  function renderFacilityTypeOptions() {
    return Object.keys(facilityTypeColors).map((facility, i) => (
      <MenuItem key={i} value={facility}>
        {facility}
      </MenuItem>
    ));
  }

  function renderAccountPrioritizationDropdown() {
    return accountPrioritizationOptions.map((priority, i) => (
      <MenuItem key={i} value={priority}>
        <ListItemIcon>
          <Checkbox checked={accountPrioritizationFilter.indexOf(priority) > -1} />
        </ListItemIcon>
        <ListItemText primary={priority} />
      </MenuItem>
    ));
  }

  const handleAccountPrioritizationChange = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setAccountPrioritizationFilter(accountPrioritizationFilter.length === accountPrioritizationOptions.length ?
        [] :
        accountPrioritizationOptions);
      return;
    }
    setAccountPrioritizationFilter(value);
  }

  const getValuesInRange = (value: number, bounds: number | number[]) => {
    if (Array.isArray(bounds) && bounds.length === 2) {
      return value >= bounds[0] && value <= bounds[1];
    } else {
      return true;
    }
  };

  return (
    <Accordion
      disabled={isDisabled}
      expanded={isAdditionalExpanded}
      onChange={() => setIsAdditionalExpanded(!isAdditionalExpanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Account Filters</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionSection>
          <FormControl className="form-control">
            <SelectLabel>Text Filter</SelectLabel>
            <input
              id="textFilter"
              value={textFilter}
              onChange={(e) => setTextFilter(e.target.value)}
            />
          </FormControl>
        </AccordionSection>
        <AccordionSection>
          <FormControl className="form-control">
            <SelectLabel>Facility Type</SelectLabel>
            <Select
              name="facilityType"
              value={facilityTypeFilter}
              onChange={(e) => setFacilityTypeFilter(e.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ height: '40px' }}
            >
              {renderFacilityTypeOptions()}
            </Select>
          </FormControl>
        </AccordionSection>
        <AccordionSection>
          <FormControl className="form-control">
            <SelectLabel>Account Prioritization</SelectLabel>
            <Select
              name="accountPrioritization"
              value={accountPrioritizationFilter}
              onChange={handleAccountPrioritizationChange}
              renderValue={(accountPrioritizationFilter) => accountPrioritizationFilter.join(", ")}
              inputProps={{ 'aria-label': 'Without label' }}
              multiple
              sx={{ height: '40px' }}
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      accountPrioritizationFilter.length > 0 && accountPrioritizationFilter.length < accountPrioritizationOptions.length
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Select All"
                />
              </MenuItem>
              {renderAccountPrioritizationDropdown()}
            </Select>
          </FormControl>
        </AccordionSection>
        <AccordionSection>
          <SelectLabel>Account Index</SelectLabel>
          <Slider
            getAriaLabel={() => 'Account Score range'}
            value={accountScoreRange}
            name="accountScore"
            onChange={(e, value) => setAccountScoreRange(value as number[])}
            valueLabelDisplay="auto"
            min={0}
            max={accountScoreMax}
          />
        </AccordionSection>
        <AccordionSection>
          <Button variant="outlined" onClick={resetAdditionalFilters}>
            Reset
          </Button>
        </AccordionSection>
      </AccordionDetails>
    </Accordion>
  )
}
