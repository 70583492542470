/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useState } from 'react';
import { Drawer, Tab, Tabs, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ManagerHeader, NavPanelForm } from './styles';
import { AppContext } from '../../app.store';
import { Facility } from '@voiant/dataconnector';
import CloseIcon from '@mui/icons-material/Close';
import { FullContext } from '../shared/types';
import TerritoryPanel from './Panels/territoryPanel';
import ConfigurationsPanel from './Panels/configurationsPanel';
import AccountsTotalPanel from './Panels/accountsTotalPanel';
import ScenarioPlanningPanel from './Panels/scenarioPlanningPanel';
import { reducerMethods } from '../../app.reducer';

enum NavPanelTabs {
  Territory,
  Planning,
  Configurations,
}

export default function NavPanel() {
  const [state, dispatch] = useContext<FullContext>(AppContext);

  const [selectedTab, setSelectedTab] = useState<number>(
    NavPanelTabs.Territory
  );
  const [isAdditionalExpanded, setIsAdditionalExpanded] =
    useState<boolean>(false);
  const [isMapSettingsExpanded, setIsMapSettingsExpanded] =
    useState<boolean>(false);
  const [unfilteredFacilities, setUnfilteredFacilities] = useState<Facility[]>(
    []
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleMenuClose = () => {
    dispatch({
      type: reducerMethods.setTerritoryManagerDisplayed,
      payload: false,
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Drawer
      anchor={'left'}
      open={state.isManagerOpen}
      hideBackdrop={true}
      variant={'persistent'}
    >
      <NavPanelForm
        onSubmit={(event: { preventDefault: () => void }) => {
          event.preventDefault();
        }}
      >
        <Stack spacing={3}>
          <ManagerHeader>
            <Typography variant="h5" component="div">
              Territory Manager
            </Typography>
            <CloseIcon
              style={{ cursor: 'pointer' }}
              onClick={handleMenuClose}
            />
          </ManagerHeader>
          <Tabs value={selectedTab} onChange={handleChange} centered>
            <Tab label="Territory" value={NavPanelTabs.Territory} />
            <Tab
              label="Planning"
              value={NavPanelTabs.Planning}
              disabled={isDisabled}
            />
            <Tab
              label="Configurations"
              value={NavPanelTabs.Configurations}
              disabled={isDisabled}
            />
          </Tabs>

          {selectedTab === NavPanelTabs.Territory && (
            <TerritoryPanel
              setIsAdditionalExpanded={setIsAdditionalExpanded}
              setIsMapSettingsExpanded={setIsMapSettingsExpanded}
              setUnfilteredFacilities={setUnfilteredFacilities}
              setIsDisabled={setIsDisabled}
            />
          )}

          {selectedTab === NavPanelTabs.Planning && <ScenarioPlanningPanel />}

          {selectedTab === NavPanelTabs.Configurations && (
            <ConfigurationsPanel
              isDisabled={isDisabled}
              isAdditionalExpanded={isAdditionalExpanded}
              setIsAdditionalExpanded={setIsAdditionalExpanded}
              unfilteredFacilities={unfilteredFacilities}
              isMapSettingsExpanded={isMapSettingsExpanded}
              setIsMapSettingsExpanded={setIsMapSettingsExpanded}
            />
          )}

          <AccountsTotalPanel
            showScenarios={selectedTab === NavPanelTabs.Planning}
          />
        </Stack>
      </NavPanelForm>
    </Drawer>
  );
}
