import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl, MenuItem,
  Select,
  Slider,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionSection, SelectLabel } from "../../styles";
import { reducerMethods } from "../../../../app.reducer";
import { FullContext } from "../../../shared/types";
import { AppContext } from "../../../../app.store";
import { Facility } from "@voiant/dataconnector";
import { facilityTypeColors } from "../../../../../../../../libs/dataconnector/src/lib/facilityTypeColors";


export const AccountFilters = ({
 isDisabled,
 isAdditionalExpanded,
 setIsAdditionalExpanded,
 unfilteredFacilities
}: {
  isDisabled: boolean;
  isAdditionalExpanded: boolean;
  setIsAdditionalExpanded: (isAdditionalExpanded: boolean) => void;
  unfilteredFacilities: Facility[];
}) => {
  const [state, dispatch] = useContext<FullContext>(AppContext);

  const [textFilter, setTextFilter] = useState<string>(
    state.additionalFilters?.textFilter ?? ''
  );
  const [accountScoreRange, setAccountScoreRange] = useState<number[]>([0, 0]);
  const [ibCountRange, setIbCountRange] = useState<number[]>([0, 0]);
  const [currentFunnelRange, setCurrentFunnelRange] = useState<number[]>([
    0, 0,
  ]);
  const [pastSalesRange, setPastSalesRange] = useState<number[]>([0, 0]);
  const [totalIBDollarValueRange, setTotalIBDollarValueRange] = useState<
    number[]
    >([0, 0]);
  const [facilityTypeFilter, setFacilityTypeFilter] = useState<string>(
    state.additionalFilters?.facilityTypeFilter ?? ''
  );
  const accountScoreMax = useMemo(
    () =>
      Math.max(
        ...unfilteredFacilities.map(
          (facility: Facility) => facility['accountScore']
        )
      ),
    [unfilteredFacilities]
  );
  const ibCountMax = useMemo(
    () =>
      Math.max(
        ...unfilteredFacilities.map(
          (facility: Facility) => facility['IB_Count']
        )
      ),
    [unfilteredFacilities]
  );
  const currentFunnelMax = useMemo(
    () =>
      Math.max(
        ...unfilteredFacilities.map((facility: Facility) =>
          Number(facility['currentFunnel'])
        )
      ),
    [unfilteredFacilities]
  );
  const pastSalesMax = useMemo(
    () =>
      Math.max(
        ...unfilteredFacilities.map(
          (facility: Facility) => facility['pastSales']
        )
      ),
    [unfilteredFacilities]
  );
  const totalIBDollarValueMax = useMemo(
    () =>
      Math.max(
        ...unfilteredFacilities.map(
          (facility: Facility) => facility['totalIBDollarValueRange']
        )
      ),
    [unfilteredFacilities]
  );

  //Updates to filter the facilities
  useEffect(() => {
    dispatch({
      type: reducerMethods.setAdditionalFilterText,
      payload: textFilter,
    });
    dispatch({
      type: reducerMethods.setAdditionalFilterAccountScore,
      payload: accountScoreRange,
    });
    dispatch({
      type: reducerMethods.setAdditionalFilterIbCount,
      payload: ibCountRange,
    });
    dispatch({
      type: reducerMethods.setAdditionalFilterCurrentFunnel,
      payload: currentFunnelRange,
    });
    dispatch({
      type: reducerMethods.setAdditionalFilterPastSalesRange,
      payload: pastSalesRange,
    });
    dispatch({
      type: reducerMethods.setAdditionalFilterTotalIbDollarValueRange,
      payload: totalIBDollarValueRange,
    });
    dispatch({
      type: reducerMethods.setAdditionalFilterFacilityType,
      payload: facilityTypeFilter,
    });

    let facilitiesBeingFiltered = unfilteredFacilities;

    if (textFilter.length > 2) {
      const lowerCasedFilter = textFilter.toLowerCase();

      facilitiesBeingFiltered = facilitiesBeingFiltered.filter((facility) => {
        return (
          facility.accountName?.toLowerCase().includes(lowerCasedFilter) ||
          facility.parentName?.toLowerCase().includes(lowerCasedFilter) ||
          facility.accountPrimaryPostal
            ?.toLowerCase()
            .includes(lowerCasedFilter) ||
          facility.ultimateParentAccountCode
            ?.toLowerCase()
            .includes(lowerCasedFilter) ||
          facility.accountUCMID?.toLowerCase().includes(lowerCasedFilter)
        );
      });
    }

    facilitiesBeingFiltered = facilitiesBeingFiltered.filter(
      (facility: { accountScore: number }) => {
        return getValuesInRange(facility.accountScore, accountScoreRange);
      }
    );

    facilitiesBeingFiltered = facilitiesBeingFiltered.filter(
      (facility: { IB_Count: number }) => {
        return getValuesInRange(facility.IB_Count, ibCountRange);
      }
    );

    facilitiesBeingFiltered = facilitiesBeingFiltered.filter(
      (facility: { currentFunnel: string }) => {
        return getValuesInRange(
          Number(facility.currentFunnel),
          currentFunnelRange
        );
      }
    );

    facilitiesBeingFiltered = facilitiesBeingFiltered.filter(
      (facility: { pastSales: number }) => {
        return getValuesInRange(facility.pastSales, pastSalesRange);
      }
    );

    facilitiesBeingFiltered = facilitiesBeingFiltered.filter(
      (facility: { totalIBDollarValueRange: number }) => {
        return getValuesInRange(
          facility.totalIBDollarValueRange,
          totalIBDollarValueRange
        );
      }
    );

    if (facilityTypeFilter.length > 0) {
      facilitiesBeingFiltered = facilitiesBeingFiltered.filter(
        (facility: { facilityType: string }) => {
          return facility.facilityType === facilityTypeFilter;
        }
      );
    }

    if (facilitiesBeingFiltered.length > 0) {
      dispatch({
        type: reducerMethods.setFacilities,
        payload: facilitiesBeingFiltered,
      });
    } else {
      dispatch({ type: reducerMethods.setFacilities, payload: [] });
    }
  }, [
    textFilter,
    accountScoreRange,
    ibCountRange,
    currentFunnelRange,
    pastSalesRange,
    totalIBDollarValueRange,
    facilityTypeFilter,
  ]);

  useEffect(() => {
    setAccountScoreRange(
      state.additionalFilters?.accountScoreRange ?? [0, accountScoreMax]
    );
    setIbCountRange(state.additionalFilters?.ibCountRange ?? [0, ibCountMax]);
    setCurrentFunnelRange(
      state.additionalFilters?.currentFunnelRange ?? [0, currentFunnelMax]
    );
    setPastSalesRange(
      state.additionalFilters?.pastSalesRange ?? [0, pastSalesMax]
    );
    setTotalIBDollarValueRange(
      state.additionalFilters?.totalIBDollarValueRange ?? [
        0,
        totalIBDollarValueMax,
      ]
    );
  }, [unfilteredFacilities]);

  function resetAdditionalFilters() {
    setTextFilter('');
    setAccountScoreRange([0, accountScoreMax]);
    setIbCountRange([0, ibCountMax]);
    setCurrentFunnelRange([0, currentFunnelMax]);
    setPastSalesRange([0, pastSalesMax]);
    setTotalIBDollarValueRange([0, totalIBDollarValueMax]);
    setFacilityTypeFilter('');
    dispatch({
      type: reducerMethods.setFacilities,
      payload: unfilteredFacilities,
    });
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  function valueLabelFormat(value: number) {
    return formatter.format(value);
  }

  function renderFacilityTypeOptions() {
    return Object.keys(facilityTypeColors).map((facility, i) => (
      <MenuItem key={i} value={facility}>
        {facility}
      </MenuItem>
    ));
  }

  const getValuesInRange = (value: number, bounds: number | number[]) => {
    if (Array.isArray(bounds) && bounds.length === 2) {
      return value >= bounds[0] && value <= bounds[1];
    } else {
      return true;
    }
  };

  return (
    <Accordion
      disabled={isDisabled}
      expanded={isAdditionalExpanded}
      onChange={() => setIsAdditionalExpanded(!isAdditionalExpanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Account Filters</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionSection>
          <FormControl className="form-control">
            <SelectLabel>Text Filter</SelectLabel>
            <input
              id="textFilter"
              value={textFilter}
              onChange={(e) => setTextFilter(e.target.value)}
            />
          </FormControl>
        </AccordionSection>
        <AccordionSection>
          <FormControl className="form-control">
            <SelectLabel>Facility Type</SelectLabel>
            <Select
              name="facilityType"
              value={facilityTypeFilter}
              onChange={(e) => setFacilityTypeFilter(e.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ height: '40px' }}
            >
              {renderFacilityTypeOptions()}
            </Select>
          </FormControl>
        </AccordionSection>
        <AccordionSection>
          <SelectLabel>Account Score</SelectLabel>
          <Slider
            getAriaLabel={() => 'Account Score range'}
            value={accountScoreRange}
            name="accountScore"
            onChange={(e, value) => setAccountScoreRange(value as number[])}
            valueLabelDisplay="auto"
            min={0}
            max={accountScoreMax}
          />
        </AccordionSection>
        <AccordionSection>
          <SelectLabel>Total IB Count</SelectLabel>
          <Slider
            getAriaLabel={() => 'IB Count range'}
            value={ibCountRange}
            name="IB_Count"
            onChange={(e, value) => setIbCountRange(value as number[])}
            valueLabelDisplay="auto"
            min={0}
            max={ibCountMax}
          />
        </AccordionSection>
        <AccordionSection>
          <SelectLabel>Current Funnel</SelectLabel>
          <Slider
            getAriaLabel={() => 'Current Funnel range'}
            value={currentFunnelRange}
            name="currentFunnel"
            onChange={(e, value) => setCurrentFunnelRange(value as number[])}
            valueLabelDisplay="auto"
            min={0}
            max={currentFunnelMax}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
          />
        </AccordionSection>
        <AccordionSection>
          <SelectLabel>Historical Market (5 Yrs Avg)</SelectLabel>
          <Slider
            getAriaLabel={() => 'Past Sales range'}
            value={pastSalesRange}
            name="pastSales"
            onChange={(e, value) => setPastSalesRange(value as number[])}
            valueLabelDisplay="auto"
            min={0}
            max={pastSalesMax}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
          />
        </AccordionSection>
        <AccordionSection>
          <SelectLabel>Total IB Dollar</SelectLabel>
          <Slider
            getAriaLabel={() => 'Total IB Dollar range'}
            value={totalIBDollarValueRange}
            name="totalIBDollarValueRange"
            onChange={(e, value) =>
              setTotalIBDollarValueRange(value as number[])
            }
            valueLabelDisplay="auto"
            min={0}
            max={totalIBDollarValueMax}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
          />
        </AccordionSection>
        <AccordionSection>
          <Button variant="outlined" onClick={resetAdditionalFilters}>
            Reset
          </Button>
        </AccordionSection>
      </AccordionDetails>
    </Accordion>
  )
}
