import { useState } from 'react';
import { FieldsDict } from '../components/shared/types';

type TargetEvent = { target: { name: string; value: string } };

export function useFormFields(initialState: FieldsDict): [fields: FieldsDict, handleFieldChange: (event: TargetEvent)=>void] {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event: TargetEvent) {
      setValues({
        ...fields,
        [event.target.name]: event.target.value,
      });
    },
  ];
}
