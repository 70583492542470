import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactMapGL, { MapEvent, MapRef, NavigationControl } from 'react-map-gl';
import {
  DrawLineStringMode,
  DrawPolygonByDraggingMode,
  DrawPolygonMode,
  EditingMode,
  Editor,
} from 'react-map-gl-draw';
import { AppContext } from '../../app.store';
import { Territory } from '@voiant/dataconnector';
import { FacilityReassignmentPanel as EmeaFacilityReassignmentPanel } from '../facility/emea/facilityReassignmentPanel';
import { FacilityReassignmentPanel as UscanFacilityReassignmentPanel } from '../facility/uscan/facilityReassignmentPanel';
import { FacilityData, FullContext } from '../shared/types';
import * as turf from '@turf/turf';

import styles from './map-box.module.scss';
import { Feature } from '@nebula.gl/edit-modes';
import createViz from './create-viz';
import { Container, PositionInfoContainer } from './styles';
import {
  getFacilityPoints,
  getPostalRegionDataForRegion,
  getRegionDataFromFeatures,
  getTerritoriesForEvent,
  getTerritoriesForPoint,
  selectRegion,
} from './region-selection';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { TerritoryReassignmentPanel as EmeaTerritoryReassignmentPanel } from '../facility/emea/territoryReassignmentPanel';
import { TerritoryReassignmentPanel as UscanTerritoryReassignmentPanel } from '../facility/uscan/territoryReassignmentPanel';
import { reducerMethods } from '../../app.reducer';
import { getMarkers } from './markers';
import { AccountPopup as UscanAccountPopup } from './uscan/accountPopup';
import { AccountPopup as EmeaAccountPopup } from './emea/accountPopup';
import { getTerritoryLabels } from './territoryLabel';

interface ViewPort {
  latitude: number;
  longitude: number;
  width: string;
  height: string;
  zoom: number;
}

export type TerritoryByRep = { territories: Array<Territory> };

export default function MapBox() {
  const [state, dispatch] = useContext<FullContext>(AppContext);
  const [allFeatureByPostals, setAllFeatureByPostalsForUser] = useState<
    Array<string>
  >([]);
  const [territoriesByRep, setTerritoriesByRep] = useState<
    Map<string, TerritoryByRep>
  >(new Map<string, TerritoryByRep>());

  const selectedSource = state.mapFilterStatus.managerFilterSelections.source;

  const token =
    'pk.eyJ1IjoibWFwbWFuMjciLCJhIjoiY2p0N21rZTduMHRjbzQ0cDM4aWEzNnZoNCJ9.0W1yZ5oX3dChiUD6AFAUSw';

  const [viewport, setViewport] = useState<ViewPort>({
    latitude: Number(state.user?.defaultLat),
    longitude: Number(state.user?.defaultLong),
    width: '100vw',
    height: 'calc(100vh - 64px)',
    zoom: state.user?.zoom || 6,
  });

  const map = useRef<MapRef | null>(null);
  const editor = useRef<Editor | null>(null);
  const [selectedFacility, setSelectedFacility] = useState<
    FacilityData | undefined
  >(undefined);
  const [selectedFacilities, setSelectedFacilities] = useState<FacilityData[]>(
    []
  );
  const [facilities, setFacilities] = useState<FacilityData[]>();
  const [hoverRegion, setHoverRegion] = useState<
    MapboxGeoJSONFeature | undefined
  >(undefined);

  const [territoryColors, setTerritoryColors] = useState<{
    [key: string]: string;
  }>({});

  const [modeId, setModeId] = useState<string>();
  const [modeHandler, setModeHandler] = useState<
    DrawLineStringMode | DrawPolygonMode | EditingMode
  >();

  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState<
    number | undefined
  >(undefined);
  const [editFeatures, setEditFeatures] = useState<Feature[]>();

  const MODES = [
    {
      id: 'drawPolygonDrag',
      text: 'Draw Polygon Drag',
      handler: DrawPolygonByDraggingMode,
    },
  ];

  const switchMode = (evtMode: string) => {
    if (evtMode !== modeId) {
      setModeId(evtMode);
      const mode = MODES.find((m) => m.id === evtMode);
      if (mode) {
        setModeHandler(new mode.handler());
      }
    }
  };

  const ToolBar = (
    <div
      style={{ position: 'absolute', bottom: 80, right: 20, maxWidth: '320px' }}
    >
      {MODES.map((mode, i) => {
        if (mode.id !== 'editing') {
          return (
            <div key={i} style={{ width: 200, textAlign: 'right' }}>
              <button
                style={{ width: 200, height: 40, marginTop: 10 }}
                value={mode.id}
                onClick={() => switchMode(mode.id)}
              >
                {mode.text}
              </button>
            </div>
          );
        } else {
          return undefined;
        }
      })}
    </div>
  );

  //On page load set center to user pref
  useEffect(() => {
    if (state.user && state.user.defaultLat) {
      map.current?.getMap().flyTo({
        center: [Number(state.user.defaultLong), Number(state.user.defaultLat)],
        zoom: 6,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });
    }
  }, [state.user]);

  const level = 'pos4';
  const country_filter = 'US'; //TODO: from mapbox-mapped versions to our own data list of country codes
  const lookup_url = '/assets/' + country_filter + '-' + level + '.json';

  //This is the default load Part 1 of the map when we have territories available.
  // Current thinking is it is triggered after Sub-Modality selection
  // Its outcome is to create the lookup data for map tiles (to set selected (postal) features)
  useEffect(() => {
    if (state.active_territories) {
      const territoriesByRep: Map<string, TerritoryByRep> = new Map<
        string,
        TerritoryByRep
      >();

      if (state.mapFilterStatus.managerFilterSelections.source === 'USCAN') {
        for (const territory of state.active_territories) {
          if (!territoriesByRep.has(territory.accountMarketCode)) {
            territoriesByRep.set(territory.accountMarketCode, {
              territories: [territory],
            });
          } else {
            territoriesByRep
              ?.get(territory.accountMarketCode)
              ?.territories.push(territory);
          }
        }
      } else {
        for (const territory of state.active_territories) {
          if (!territoriesByRep.has(territory.accountPSS)) {
            territoriesByRep.set(territory.accountPSS, {
              territories: [territory],
            });
          } else {
            territoriesByRep
              ?.get(territory.accountPSS)
              ?.territories.push(territory);
          }
        }
      }

      setTerritoriesByRep(territoriesByRep);

      const featureByPostals = state.active_territories
        .map((t: Territory) => t.postalCodes)
        .flat();
      setAllFeatureByPostalsForUser(featureByPostals);
    }
  }, [state.active_territories]);

  //This is the default load Part 2 of the map when we have territories available.
  // Its outcome is to DISPLAY all the postal codes the logged in user has in all territories
  useEffect(() => {
    if (allFeatureByPostals) {
      dispatch({ type: reducerMethods.addLoad });
      //Flatten territoriesByRep into the datastructure for the boundary iteration
      const matchData = new Map<Array<number>, [string, string]>();
      territoriesByRep.forEach((t: TerritoryByRep, repId: string) => {
        const colors: { [key: string]: string } = {};
        for (const territory of t.territories) {
          colors[territory.accountFinalTerritoryCode] = territory.repColor;
          for (const featureId of territory.postalCodes) {
            matchData.set(
              [featureId[0]],
              [territory.accountFinalTerritoryCode, territory.repColor]
            ); // Feature Id, [territorycode, Color]
          }
        }
        setTerritoryColors(colors);
      });
      createViz(map.current?.getMap(), matchData, state.active_country ?? '');
      map.current?.getMap().once('idle', () => {
        dispatch({ type: reducerMethods.completeLoad });
      });
    }
  }, [allFeatureByPostals]);

  function getActiveFacilities() {
    let activeFacilities = state.facilities;
    if (state.active_scenario === 1) {
      activeFacilities = activeFacilities.filter(
        (facility) =>
          facility.accountFinalTerritoryCode === state.territory ||
          facility.scenarioOneCode === state.territory
      );
    } else if (state.active_scenario === 2) {
      activeFacilities = activeFacilities.filter(
        (facility) =>
          facility.accountFinalTerritoryCode === state.territory ||
          facility.scenarioTwoCode === state.territory
      );
    } else if (state.selectedTab === 1) {
      setFacilities(activeFacilities);
      return activeFacilities;
    } else {
      activeFacilities = activeFacilities.filter(
        (facility) => facility.accountFinalTerritoryCode === state.territory
      );
    }
    setFacilities(activeFacilities);
    return activeFacilities;
  }

  //This is a tertiary load to display facilities for the selected territory
  const markers = useMemo(() => {
    return getMarkers(
      map.current?.getMap(),
      state,
      territoryColors,
      getActiveFacilities(),
      selectedFacilities,
      setSelectedFacilities,
      setSelectedFacility
    );
  }, [
    state.facilities,
    state.facilityPinColorSettings,
    state.active_scenario,
    viewport.zoom,
  ]);

  const labels = useMemo(() => {
    if (state.active_territories.length > 0) {
      return getTerritoryLabels(state.active_territories);
    } else {
      return;
    }
  }, [state.active_territories]);

  useEffect(() => {
    setSelectedFacilities(state.selected_facilities ?? []);
  }, [state.selected_facilities]);

  useEffect(() => {
    setSelectedFacility(undefined);
    console.log('selectedFacilities', selectedFacilities);
  }, [selectedFacilities]);

  // Explicitly marked as any by mapbox-gl
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdate = (payload: { editType: string; data: any[] }) => {
    const features = payload.data;
    if (payload.editType === 'addFeature') {
      const data = payload.data;
      if (data.length > 1) {
        data.shift();
      }
      const boundaryPoints = data[0].geometry.coordinates[0];

      const searchZone = turf.polygon([boundaryPoints]);
      if (state.allowRegionSelect) {
        const intersections = getPostalRegionDataForRegion(
          searchZone,
          map.current?.getMap(),
          state.active_territories,
          facilities ?? []
        );
        dispatch({
          type: reducerMethods.setSelectedPostalRegions,
          payload: intersections,
        });
      } else {
        const facilityPoints = getFacilityPoints(facilities);
        if (!facilityPoints) {
          return;
        }

        const ptsWithin = turf.pointsWithinPolygon(facilityPoints, searchZone);
        const facilitiesWithin = ptsWithin.features.map((feature) => {
          return feature.properties;
        });
        setSelectedFacilities(facilitiesWithin);
        dispatch({
          type: reducerMethods.setSelectedFacilities,
          payload: facilitiesWithin,
        });
      }
      switchMode('editing');
    }
    setEditFeatures(features);
  };

  const onSelect = (selected: { selectedFeatureIndex: number }) => {
    setSelectedFeatureIndex(selected.selectedFeatureIndex);
  };

  const clearMapboxFeatures = () => {
    editor.current?.deleteFeatures([
      ...Array(editFeatures?.length ?? 0).keys(),
    ]);
    setEditFeatures(undefined);
  };

  const selectOnClick = (e: MapEvent) => {
    if (facilities && !state.allowRegionSelect) {
      const regionFacilities = selectRegion(e, facilities);
      setSelectedFacilities(regionFacilities);
      dispatch({
        type: reducerMethods.setSelectedFacilities,
        payload: regionFacilities,
      });
    } else if (state.territory && state.allowRegionSelect) {
      const clickZone = getTerritoriesForEvent(e);
      const foundPostal = getRegionDataFromFeatures(
        state.active_territories,
        clickZone,
        facilities ?? []
      );
      if (
        foundPostal.length === 1 &&
        !state.selected_postal_regions?.find(
          (postalData) => postalData.postalCode === foundPostal[0].postalCode
        )
      ) {
        dispatch({
          type: reducerMethods.setSelectedPostalRegions,
          payload: [...(state.selected_postal_regions ?? []), foundPostal[0]],
        });
      }
    }
  };

  const highlightHoveredRegion = (e: MapEvent) => {
    if (
      !facilities ||
      !state.allowRegionSelect ||
      modeId === 'drawPolygonDrag'
    ) {
      return;
    }
    const instMap = map.current?.getMap();
    const setHoverState = (feature: MapboxGeoJSONFeature, state: boolean) => {
      instMap.setFeatureState(
        {
          source: feature.source,
          id: feature.id,
          sourceLayer: feature.sourceLayer,
        },
        { hover: state }
      );
    };

    if (instMap) {
      const features = getTerritoriesForPoint(e.lngLat, map.current?.getMap());
      if (features.length > 0) {
        if (!hoverRegion) {
          instMap.getCanvas().style.cursor = 'pointer';
          setHoverRegion(features[0]);
          setHoverState(features[0], true);
        } else if (hoverRegion && features[0] !== hoverRegion) {
          setHoverState(hoverRegion, false);
          setHoverRegion(features[0]);
          setHoverState(features[0], true);
        }
      } else if (hoverRegion) {
        instMap.getCanvas().style.cursor = '';
        setHoverState(hoverRegion, false);
        setHoverRegion(undefined);
      }
    }
  };

  const facilityReassignmentPanel =
    selectedSource === 'USCAN' ? (
      <UscanFacilityReassignmentPanel
        clearMapboxSelection={clearMapboxFeatures}
      />
    ) : (
      <EmeaFacilityReassignmentPanel
        clearMapboxSelection={clearMapboxFeatures}
      />
    );

  const territoryReassignmentPanel =
    selectedSource === 'USCAN' ? (
      <UscanTerritoryReassignmentPanel
        clearMapboxSelection={clearMapboxFeatures}
      />
    ) : (
      <EmeaTerritoryReassignmentPanel
        clearMapboxSelection={clearMapboxFeatures}
      />
    );

  return (
    <Container>
      <ReactMapGL
        ref={map}
        {...viewport}
        mapboxApiAccessToken={token}
        mapStyle="mapbox://styles/mapbox/light-v10"
        onViewportChange={(viewport: ViewPort) => {
          setViewport(viewport);
        }}
        onClick={selectOnClick}
        onMouseMove={highlightHoveredRegion}
      >
        {state.showTerritoryLabels &&
          state.shouldShowTerritoryDropdown &&
          labels}
        {markers}
        <PositionInfoContainer>
          <div>
            <span>lat: {viewport.latitude}</span>
            <span>long: {viewport.longitude}</span>
            <span>zoom: {viewport.zoom}</span>
            <span>mode: {modeId}</span>
          </div>
        </PositionInfoContainer>
        <NavigationControl className={styles.navControlStyle} />
        <Editor
          // to make the lines/vertices easier to interact with
          ref={editor}
          clickRadius={12}
          mode={modeHandler}
          modeConfig={{ dragToDraw: modeId === 'drawPolygonDrag' }}
          onSelect={onSelect}
          onUpdate={onUpdate}
          selectedFeatureIndex={selectedFeatureIndex}
          features={editFeatures}
          editHandleShape={'circle'}
        />
        {selectedFacility ? (
          selectedSource === 'USCAN' ? (
            <UscanAccountPopup
              selectedFacility={selectedFacility}
              setSelectedFacility={setSelectedFacility}
            />
          ) : (
            <EmeaAccountPopup
              selectedFacility={selectedFacility}
              setSelectedFacility={setSelectedFacility}
            />
          )
        ) : null}
      </ReactMapGL>
      {(facilities || (state.allowRegionSelect && state.territory)) && ToolBar}
      {state.allowRegionSelect
        ? territoryReassignmentPanel
        : facilityReassignmentPanel}
    </Container>
  );
}
