import { Marker } from 'react-map-gl';
import { ColorBox, LabelText, TerritoryLabel } from './styles';
import { Territory } from '@voiant/dataconnector';

export const getTerritoryLabels = (activeTerritories: Territory[]) => {
  const labels: JSX.Element[] = [];

  activeTerritories.forEach((terr) => {
    labels.push(
      <Marker
        key={terr.accountFinalTerritoryCode + 'terr-label'}
        longitude={terr.centroid[0]}
        latitude={terr.centroid[1]}
        className={'territoryMarker'}
      >
        <TerritoryLabel>
          <ColorBox color={terr.repColor} />
          <LabelText>
            {`${terr.accountFinalTerritory} (${terr.territoryScore
              .toFixed(2)
              .toString()})`}
          </LabelText>
        </TerritoryLabel>
      </Marker>
    );
  });

  return labels;
};
