import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Avatar, Dialog, DialogTitle, Stack, TextField } from '@mui/material';
import { AppContext } from '../../app.store';
import React, { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import { FullContext } from '../shared/types';
import { reducerMethods } from '../../app.reducer';

export default function ToolBar() {
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [state, dispatch] = useContext<FullContext>(AppContext);
  const [open, setOpen] = useState(false);

  const handleMenu = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget ?? null);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleSettings = () => {
    setOpen(true);
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const handleMenuClick = () => {
    dispatch({
      type: reducerMethods.setTerritoryManagerDisplayed,
      payload: true,
    });
  };

  function getFirstTwoLetters() {
    return state.user?.userEmail?.slice(0, 2).toUpperCase();
  }

  // whitespace change
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            GE Healthcare
          </Typography>
          {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar sx={{ bgcolor: '#4caf50' }}>
                  {getFirstTwoLetters()}
                </Avatar>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleSettings}>Settings</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>Settings</DialogTitle>
        <Box
          sx={{
            width: '100%',
            padding: '20px',
          }}
        >
          <Stack>
            <TextField
              required
              id="outlined"
              label="Username"
              defaultValue={state.user?.username}
            />
            <br />
            <br />
            <TextField
              required
              id="outlined"
              label="Password"
              type="password"
              defaultValue={state.user?.password}
            />
          </Stack>
        </Box>
      </Dialog>
    </Box>
  );
}
