import styled from '@emotion/styled';

export const FacilityTableContainer = styled.div`
  position: absolute;
  bottom: 80px;
  right: 20px;
  background-color: white;
  width: 100%;
  max-width: 900px;
  z-index: 3;

  fieldset {
    legend {
      width: unset;
    }
  }
`;

export const FacilityDatagrid = styled.div`
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: scroll;
`;

export const FacilityFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 20px;

  Button {
    margin: 10px 20px 10px 0;
  }
`;

export const TerritoryAssignmentDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
